import React from 'react'
import styles from './OfflineMode.module.css'

export default function OfflineMode() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.imgContent}>
          <img src="/image/nelinkaIcon.svg" alt="LOGO" className={styles.img} />
        </div>
        <h4 className={styles.text}>
          <hr className={styles.highLine} />
          Կայքը մշակման փուլում է,վերադարձեք ավելի ուշ
          <hr className={styles.downLine} />
        </h4>
      </div>
    </div>
  )
}
