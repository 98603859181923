import { FadeLoader } from 'react-spinners'
import styles from './Loader.module.css'

export default function Loader() {
  return (
    <div className={styles.overlay}>
      <div className={styles.spinner}>
        <FadeLoader color="rgb(202,129,178)" />
      </div>
    </div>
  )
}
