import styles from './AdminDashboard.module.css'
import axios from 'axios'
import MenuIcon from '@mui/icons-material/Menu'
import ColorLensIcon from '@mui/icons-material/ColorLens'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import PropTypes from 'prop-types'
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem'
import Typography from '@mui/material/Typography'
import GridViewIcon from '@mui/icons-material/GridView'
import EngineeringIcon from '@mui/icons-material/Engineering'
import Cookies from 'js-cookie'
import { BsFillVolumeUpFill } from 'react-icons/bs'
import { IoLogOut } from 'react-icons/io5'
import { AiFillInfoCircle } from 'react-icons/ai'
import { MdProductionQuantityLimits, MdContactPhone } from 'react-icons/md'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { BiSlideshow } from 'react-icons/bi'
import { Outlet } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'
import { Drawer, useMediaQuery } from '@mui/material'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { BsFillPeopleFill, BsFillUsbMiniFill } from 'react-icons/bs'
import { CgWebsite } from 'react-icons/cg'

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}))

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  )
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
}

function AdminDashboard() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [active, setActive] = useState('aboutUs')
  const matches = useMediaQuery('(min-width:978px)')
  const [open, setOpen] = useState(false)

  async function handleLogout() {
    try {
      await axios
        .put(`${process.env.REACT_APP_SERVER_API}/isAdminNelinka`, {
          isAdmin: 0,
        })
        .then(() => Cookies.set('isAdmin', false))
        .then(() => window.location.reload())
    } catch (error) {
      console.log(error)
    }
  }

  const page = pathname.split('/')[3]

  const toggleDrawer = () => {
    setOpen(!open)
  }

  function handleChangeActive(name) {
    setActive(name)
  }

  useEffect(() => {
    if (page === 'infoAccordeon') {
      setActive('infoAccordeon')
    } else if (page === 'partners') {
      setActive('partners')
    }
  }, [page])

  return (
    <>
      <div className={styles.container}>
        <nav className={styles.navbar}>
          <h1 className={styles.adminNavTitle}>Կառավարման Վահանակ</h1>
        </nav>
        <main
          className={
            (active === 'infoAccordeon' || active === 'partners') && styles.main
          }>
          <Outlet />
        </main>
        {matches ? (
          <div className={styles.sidebar}>
            <div className={styles.sidebarTitle}>
              <div className={styles.sidebarImg}>
                <img
                  src="/image/nelinkaIcon.svg"
                  alt="logo"
                  className={styles.img}
                />
              </div>
              <BsFillVolumeUpFill className={styles.icon} />
            </div>

            <div className={styles.sidebarMenu}>
              <h2>Էջեր</h2>
              <Link
                to="/admin/admindashboard/aboutUs"
                onClick={() => handleChangeActive('aboutUs')}>
                <div
                  onClick={() => handleChangeActive('aboutUs')}
                  className={`${styles.sidebarLink} ${
                    active === 'aboutUs' ? styles.activeMenuLink : ''
                  }`}>
                  {/* <AiFillInfoCircle
                    onClick={() => handleChangeActive('aboutUs')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/aboutUs"
                    onClick={() => handleChangeActive('aboutUs')}>
                    Մեր Մասին
                  </Link>
                </div>
              </Link>

              <Link
                to="/admin/admindashboard/products"
                onClick={() => handleChangeActive('products')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'products' && styles.activeMenuLink
                  }`}>
                  {/* <MdProductionQuantityLimits
                    onClick={() => handleChangeActive('products')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/products"
                    onClick={() => handleChangeActive('products')}>
                    Ապրանքներ
                  </Link>
                </div>
              </Link>

              <Link
                to="/admin/admindashboard/contacts"
                onClick={() => handleChangeActive('contacts')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'contacts' && styles.activeMenuLink
                  }`}>
                  {/* <MdContactPhone
                    onClick={() => handleChangeActive('contacts')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/contacts"
                    onClick={() => handleChangeActive('contacts')}>
                    Կապ
                  </Link>
                </div>
              </Link>
              <Link
                to="/admin/admindashboard/main"
                onClick={() => handleChangeActive('main')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'main' && styles.activeMenuLink
                  }`}>
                  {/* <EngineeringIcon
                    onClick={() => handleChangeActive('main')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/main"
                    onClick={() => handleChangeActive('main')}>
                    Գլխավոր Տեքստ
                  </Link>
                </div>
              </Link>
              {/* <Link
                to="/admin/admindashboard/mainInformation"
                onClick={() => handleChangeActive('mainInformation')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'mainInformation' && styles.activeMenuLink
                  }`}>
                  <BsFillUsbMiniFill
                    onClick={() => handleChangeActive('mainInformation')}
                    className={styles.icon}
                  />
                  <Link
                    to="/admin/admindashboard/mainInformation"
                    onClick={() => handleChangeActive('mainInformation')}>
                    Main Information
                  </Link>
                </div>
              </Link> */}

              <h2>Պաստառներ</h2>
              <Link
                to="/admin/admindashboard/mainBaners"
                onClick={() => handleChangeActive('mainBaner')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'mainBaner' && styles.activeMenuLink
                  }`}>
                  {/* <BiSlideshow
                    onClick={() => handleChangeActive('mainBaner')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/mainBaners"
                    onClick={() => handleChangeActive('mainBaner')}>
                    Գլխավոր պաստառ
                  </Link>
                </div>
              </Link>

              <h2>Ապրանքների Տվյալներ</h2>
              <Link
                to="/admin/admindashboard/categories"
                onClick={() => handleChangeActive('categories')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'categories' && styles.activeMenuLink
                  }`}>
                  {/* <BiSlideshow
                    onClick={() => handleChangeActive('categories')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/categories"
                    onClick={() => handleChangeActive('categories')}>
                    Կատեգորիա
                  </Link>
                </div>
              </Link>
              <Link
                to="/admin/admindashboard/sleeves"
                onClick={() => handleChangeActive('sleeves')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'sleeves' && styles.activeMenuLink
                  }`}>
                  {/* <BiSlideshow
                    onClick={() => handleChangeActive('sleeves')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/sleeves"
                    onClick={() => handleChangeActive('sleeves')}>
                    Թևի Տեսակ
                  </Link>
                </div>
              </Link>
              <Link
                to="/admin/admindashboard/seasons"
                onClick={() => handleChangeActive('seasons')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'seasons' && styles.activeMenuLink
                  }`}>
                  {/* <BiSlideshow
                    onClick={() => handleChangeActive('seasons')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/seasons"
                    onClick={() => handleChangeActive('seasons')}>
                    Տարվա Եղանակ
                  </Link>
                </div>
              </Link>
              <Link
                to="/admin/admindashboard/productsColor"
                onClick={() => handleChangeActive('colors')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'colors' && styles.activeMenuLink
                  }`}>
                  {/* <ColorLensIcon
                    onClick={() => handleChangeActive('colors')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/productsColor"
                    onClick={() => handleChangeActive('colors')}>
                    Գույներ
                  </Link>
                </div>
              </Link>
              <Link
                to="/admin/admindashboard/sizes"
                onClick={() => handleChangeActive('sizes')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'sizes' && styles.activeMenuLink
                  }`}>
                  {/* <ColorLensIcon
                    onClick={() => handleChangeActive('sizes')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/sizes"
                    onClick={() => handleChangeActive('sizes')}>
                    Չափսեր
                  </Link>
                </div>
              </Link>
              <Link
                to="/admin/admindashboard/decorativeElements"
                onClick={() => handleChangeActive('decorativeElements')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'decorativeElements' && styles.activeMenuLink
                  }`}>
                  {/* <ColorLensIcon
                    onClick={() => handleChangeActive('decorativeElements')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/decorativeElements"
                    onClick={() => handleChangeActive('decorativeElements')}>
                    Դեկորատիվ Էլեմենտներ
                  </Link>
                </div>
              </Link>
              <Link
                to="/admin/admindashboard/genders"
                onClick={() => handleChangeActive('genders')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'genders' && styles.activeMenuLink
                  }`}>
                  {/* <ColorLensIcon
                    onClick={() => handleChangeActive('genders')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/genders"
                    onClick={() => handleChangeActive('genders')}>
                    Սեռ
                  </Link>
                </div>
              </Link>
              <Link
                to="/admin/admindashboard/productsMaterials"
                onClick={() => handleChangeActive('materials')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'materials' && styles.activeMenuLink
                  }`}>
                  {/* <PrecisionManufacturingIcon
                    onClick={() => handleChangeActive('materials')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/productsMaterials"
                    onClick={() => handleChangeActive('materials')}>
                    Մատերիալներ
                  </Link>
                </div>
              </Link>

              <h2>Գլխավոր կարգավորումներ</h2>
              <Link
                to="/admin/admindashboard/websiteStatus"
                onClick={() => handleChangeActive('websiteStatus')}>
                <div
                  className={`${styles.sidebarLink} ${
                    active === 'websiteStatus' && styles.activeMenuLink
                  }`}>
                  {/* <PrecisionManufacturingIcon
                    onClick={() => handleChangeActive('websiteStatus')}
                    className={styles.icon}
                  /> */}
                  <Link
                    to="/admin/admindashboard/websiteStatus"
                    onClick={() => handleChangeActive('materials')}>
                    Կայքի կարգավիճակը
                  </Link>
                </div>
              </Link>
              <div className={styles.sidebarLogout}>
                <IoLogOut
                  className={styles.logoutIcon}
                  color="rgb(207, 75, 149)"
                />
                <Link to="/" onClick={handleLogout}>
                  Դուրս գալ
                </Link>
              </div>
              <div className={styles.sidebarGoBackWebsite}>
                <CgWebsite className={styles.goToWebsite} />
                <Link to="/">Ելք դեպի կայք</Link>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.menuButtonContainer}>
            <MenuIcon className={styles.menuButton} onClick={toggleDrawer} />
          </div>
        )}

        <Drawer
          className={styles.drawer}
          anchor="right"
          open={open}
          onClose={toggleDrawer}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
            p={2}
            width="300px"
            role="presentation"
            textAlign="center">
            <div className={styles.actions}>
              <div className={`${styles.sidebarLink}`}>
                <GridViewIcon className={styles.iconDrawer} />
                <span className={styles.titleDashboard}>Dashboard</span>
              </div>
              <h2 className={styles.tlt}>Էջեր</h2>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'aboutUs' ? styles.activeMenuLink : ''
                }`}>
                <AiFillInfoCircle
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('aboutUs')
                  }}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/aboutUs"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('aboutUs')
                  }}>
                  About Us
                </Link>
              </div>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'products' && styles.activeMenuLink
                }`}>
                <MdProductionQuantityLimits
                  onClick={() => handleChangeActive('products')}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/products"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('products')
                  }}>
                  Products
                </Link>
              </div>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'recentProjects' && styles.activeMenuLink
                }`}>
                <MdProductionQuantityLimits
                  onClick={() => handleChangeActive('recentProjects')}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/recentProjects"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('recentProjects')
                  }}>
                  Recent Projects
                </Link>
              </div>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'partners' && styles.activeMenuLink
                }`}>
                <BsFillPeopleFill
                  onClick={() => handleChangeActive('partners')}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/partners"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('partners')
                  }}>
                  Partners
                </Link>
              </div>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'main' && styles.activeMenuLink
                }`}>
                <EngineeringIcon
                  onClick={() => handleChangeActive('main')}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/main"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('main')
                  }}>
                  Main
                </Link>
              </div>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'mainInformation' && styles.activeMenuLink
                }`}>
                <BsFillUsbMiniFill
                  onClick={() => handleChangeActive('mainInformation')}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/mainInformation"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('mainInformation')
                  }}>
                  Main Information
                </Link>
              </div>

              <div
                className={`${styles.sidebarLink} ${
                  active === 'contacts' && styles.activeMenuLink
                }`}>
                <MdContactPhone
                  onClick={() => handleChangeActive('contacts')}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/contacts"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('contacts')
                  }}>
                  Contacts
                </Link>
              </div>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'infoAccordeon' && styles.activeMenuLink
                }`}>
                <AiOutlineClose
                  onClick={() => handleChangeActive('infoAccordeon')}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/infoAccordeon"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('infoAccordeon')
                  }}>
                  Info Accordeon
                </Link>
              </div>
              <h2 className={styles.tlt}>Baners</h2>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'mainBaner' && styles.activeMenuLink
                }`}>
                <BiSlideshow
                  onClick={() => handleChangeActive('mainBaner')}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/mainBaners"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('mainBaner')
                  }}>
                  Main Baner
                </Link>
              </div>
              <h2 className={styles.tlt}>Products</h2>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'categories' && styles.activeMenuLink
                }`}>
                <BiSlideshow
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('categories')
                  }}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/categories"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('categories')
                  }}>
                  Categories
                </Link>
              </div>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'colors' && styles.activeMenuLink
                }`}>
                <ColorLensIcon
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('colors')
                  }}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/productsColor"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('colors')
                  }}>
                  Products Colors
                </Link>
              </div>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'materials' && styles.activeMenuLink
                }`}>
                <PrecisionManufacturingIcon
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('materials')
                  }}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/productsMaterials"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('materials')
                  }}>
                  Product Materials
                </Link>
              </div>
              <h2 className={styles.tlt}>Global Settings</h2>
              <div
                className={`${styles.sidebarLink} ${
                  active === 'websiteStatus' && styles.activeMenuLink
                }`}>
                <PrecisionManufacturingIcon
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('websiteStatus')
                  }}
                  className={styles.iconDrawer}
                />
                <Link
                  to="/admin/admindashboard/websiteStatus"
                  onClick={() => {
                    toggleDrawer()
                    handleChangeActive('websiteStatus')
                  }}>
                  Website Status
                </Link>
              </div>
            </div>
            <div className={styles.sidebarLogoutContainer}>
              <IoLogOut className={styles.logoutIconDrawer} />
              <Link to="/" onClick={handleLogout}>
                Global Log out
              </Link>
            </div>
            <div className={styles.sidebarGoBackWebsiteContainer}>
              <CgWebsite className={styles.goToWebsiteDrawer} />
              <Link
                to="/"
                onClick={() =>
                  setTimeout(() => window.location.reload(), 2000)
                }>
                Go Back to Website
              </Link>
            </div>
          </Box>
        </Drawer>
      </div>
    </>
  )
}

export default AdminDashboard
