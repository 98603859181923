import React from 'react'
import styles from './ProductPage.module.css'
import Footer from '../../components/Footer/Footer'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { useState, useEffect } from 'react'
import './swiperslider.css'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Description from './Description/Description'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Typography } from '@mui/material'
import ProductsHeader from '../../components/ProductsHeader/ProductsHeader'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import { AiOutlineClose } from 'react-icons/ai'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Form, Input, Tag } from 'antd'
import emailjs from '@emailjs/browser'

const { TextArea } = Input

const customizeRequiredMark = (label, { required }) => (
  <>
    <Tag color="error">Required</Tag>
  </>
)

export default function ProductPage() {
  const { confirm } = Modal
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState([])
  const { pathname } = useLocation()
  const lang = useSelector((state) => state.lang.lang)
  const productId = pathname.split('/')[3]
  const [sendingValues, setSendingValues] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    activeSize: {},
    activeColor: '',
    message: '',
  })
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [productColors, setProductColors] = useState([])
  const [producMaterials, setProductMaterials] = useState([])
  const [openLight, setOpenLight] = React.useState(false)
  const [form] = Form.useForm()
  const [requiredMark, setRequiredMarkType] = useState('optional')
  const [orderLoading, setOrderLoading] = useState(false)

  const onRequiredTypeChange = (data) => {
    console.log('onRequiredTypeChange')
    console.log(data)
    // setRequiredMarkType(requiredMarkValue)
  }

  function stripHTMLTags(htmlString) {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlString
    return tempElement.innerText
  }

  useEffect(() => {
    console.log('USE EFFECT')
    console.log(product)
    const metaTag = document.querySelector('meta[property="og:image"]')
    metaTag.setAttribute('content', product?.imgs && product?.imgs[0]?.jpeg)
    const metaTagTitle = document.querySelector('meta[property="og:title"]')
    metaTagTitle.setAttribute(
      'content',
      product[`name_${lang.toLowerCase()}`] &&
        product[`name_${lang.toLowerCase()}`]
    )
    const desc = stripHTMLTags(
      product[`description_${lang.toLowerCase()}`] &&
        product[`description_${lang.toLowerCase()}`]
    )

    const metaDescription = document.querySelector(
      'meta[property="og:description"]'
    )
    metaDescription.setAttribute('content', desc)

    return () => {
      metaTag.setAttribute(
        'content',
        product?.imgs && product[0]?.imgs[0]?.jpeg
      )
      metaTagTitle.setAttribute(
        'content',
        product[`name_${lang.toLowerCase()}`] &&
          product[`name_${lang.toLowerCase()}`]
      )
      metaDescription.setAttribute('content', desc)
    }
  }, [product, productId])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleChange = (e) => {
    setSendingValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  console.log('SENDING VALUES')
  console.log(sendingValues)

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeColor = (value) => {
    console.log('HANDLE CHANGE COLOR')
    console.log(value)

    setSendingValues((prev) => ({ ...prev, activeColor: value }))
  }

  const info = () => {
    confirm({
      icon: (
        <IoMdInformationCircleOutline size={30} color="rgb(207, 75, 149)" />
      ),
      title: t('abouttheOrder'),
      content: (
        <div>
          <div style={{ marginBottom: '30px' }}>{t('aboutFirstInfo')}</div>
          <div style={{ marginBottom: '20px' }}>{t('aboutSecondInfo')}</div>
        </div>
      ),
      onOk() {
        handleClickOpen(true)
      },
      onCancel() {
        console.log('Cancelled')
      },
      okButtonProps: {
        style: { background: 'rgb(207, 75, 149)', color: 'white' },
      },
      cancelButtonProps: {
        style: {
          border: 'rgb(207, 75, 149)',
          color: 'rgb(207, 75, 149)',
          boxShadow:
            'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        },
      },
      okText: t('confirm'),
      cancelText: t('cancel'),
    })
  }

  const parseProp = (item, lowerCaseLang, prop) => {
    return item[`${prop}_${lowerCaseLang}`]
      ? JSON.parse(item[`${prop}_${lowerCaseLang}`])
      : []
  }

  const fetchProduct = async () => {
    setLoading(true)
    try {
      const lowerCaseLanguage = lang.toLowerCase()
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/productsNelinka/${productId}`
      )

      const productsWithParsedJSON = res.data.map((item) => {
        const imgs = parseProp(item, lowerCaseLanguage, 'imgs')
        const colors = parseProp(item, lowerCaseLanguage, 'colors')
        const materials = parseProp(item, lowerCaseLanguage, 'material')
        const categories = parseProp(item, lowerCaseLanguage, 'category')
        const size = parseProp(item, lowerCaseLanguage, 'size')
        const sleeve = parseProp(item, lowerCaseLanguage, 'sleeve')
        const season = parseProp(item, lowerCaseLanguage, 'season')
        const decorativeElement = parseProp(
          item,
          lowerCaseLanguage,
          'decorativeElement'
        )

        const filteredMaterials = materials.filter(
          (item) => item.lang === lowerCaseLanguage
        )
        const categoryItem = categories.filter(
          (item) => item.lang === lowerCaseLanguage
        )
        const filteredSleeve = sleeve.filter(
          (item) => item.lang === lowerCaseLanguage
        )
        const filteredSeason = season.filter(
          (item) => item.lang === lowerCaseLanguage
        )
        const filteredDecorativeElement = decorativeElement.filter(
          (item) => item.lang === lowerCaseLanguage
        )

        const categoryValue =
          categoryItem.length > 0 ? categoryItem[0].category : null

        return {
          ...item,
          imgs,
          colors: [...colors.map((color) => color.color)],
          material: filteredMaterials,
          category: categoryValue,
          allCategories: categories,
          allMaterials: materials,
          size: size,
          season: filteredSeason,
          decorativeElement: filteredDecorativeElement,
          sleeve: filteredSleeve,
        }
      })

      const parsedDatas = productsWithParsedJSON[0]
      setProduct(parsedDatas)
      setProductMaterials(
        parsedDatas.allMaterials.filter(
          (item) => item.lang === lowerCaseLanguage
        )
      )
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchProduct()
  }, [])

  useEffect(() => {
    fetchProduct()
  }, [lang])

  const fetchColors = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/colorsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => item.lang === lang.toLowerCase())
          .filter((item) => product.colors.includes(item.color))

      setProductColors(filteredDatas)
    } catch (error) {
      console.log('PRODUCT PAGE FETCH COLORS ERROR')
      console.log(error.message)
    }
  }

  useEffect(() => {
    fetchColors()
  }, [lang, product])

  useEffect(() => {
    const filterMaterialLang = () => {
      const allProductMaterials = [...product.allMaterials]

      setProductMaterials(
        allProductMaterials.filter((item) => item.lang === lang.toLowerCase())
      )
    }
    if (product?.allMaterials?.length) {
      filterMaterialLang()
    }
  }, [lang])

  const sendEmail = (e) => {
    e.preventDefault()

    const fullUrl = window.location.href

    emailjs
      .send(
        'service_2qcysnk',
        'template_9pdewpr',
        {
          from_name: sendingValues.fullName,
          product_name: product[`name_${lang.toLowerCase()}`],
          user_email: sendingValues.email,
          user_phone: sendingValues.phoneNumber,
          product_color: sendingValues.activeColor,
          product_size: sendingValues.activeSize.size,
          product_pathname: fullUrl,
          message: sendingValues.message,
        },
        '4q6SEzAJC0Bsto3s_'
      )
      .then(() => {
        setOpen(false)
        setSendingValues({
          fullName: '',
          email: '',
          phoneNumber: '',
          activeSize: {},
          activeColor: '',
          message: '',
        })
      })
  }

  // const handleTest = () => {
  //   const promise = axios.get(
  //     'https://jsonplaceholder.typicode.com/comments?postId=1'
  //   )

  //   toast.promise(promise, {
  //     loading: 'Saving...',
  //     success: <b>Settings saved!</b>,
  //     error: <b>Could not save.</b>,
  //   })
  // }

  return (
    <>
      <ProductsHeader />
      <section className={styles.section}>
        <div className={styles.crumbsWrapper}>
          <div role="presentation" className={styles.crumbsHeader}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="red" href="/">
                {t('main')}
              </Link>
              <Link underline="hover" color="red" href="/allProducts">
                {t('products')}
              </Link>
              <Typography color="black">
                {product[`name_${lang.toLowerCase()}`]
                  ? product[`name_${lang.toLowerCase()}`]
                  : ''}
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className={styles.containerWrapper}>
          <div className={styles.container}>
            <div className={styles.productDetails}>
              <div className={styles.left}>
                <Swiper
                  style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                  }}
                  spaceBetween={3}
                  resizeObserver={true}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2">
                  {product?.imgs &&
                    product.imgs.map((item) => (
                      <SwiperSlide>
                        <img
                          src={item.webp}
                          onClick={() => setOpenLight(true)}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
                <div className={styles.dotsContainer}>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper">
                    {product?.imgs &&
                      product.imgs.map((item) => (
                        <SwiperSlide className="dotsImgParent">
                          <img src={item.jpeg} className="dotsImg" />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.nameContainer}>
                  <p className={styles.name}>
                    {product[`name_${lang.toLowerCase()}`]
                      ? product[`name_${lang.toLowerCase()}`]
                      : ''}
                  </p>
                </div>
                <div className={styles.sizeWrapper}>
                  <div className={styles.sizeTitleWrapper}>
                    <span className={styles.descriptionTitle}>
                      {t('sizes')}
                    </span>
                  </div>
                  <div className={styles.sizeListContainer}>
                    {product?.size &&
                      product.size.map((item) => {
                        const parts = item.size.split('-')

                        return (
                          <div
                            key={item.id}
                            onClick={() =>
                              setSendingValues((prev) => ({
                                ...prev,
                                activeSize: item,
                              }))
                            }
                            className={`${styles.sizeItem} ${
                              sendingValues.activeSize?.size &&
                              sendingValues.activeSize.size === item.size
                                ? styles.activeSize
                                : ''
                            }`}>
                            <span className={styles.sizeItemTitle}>
                              {parts[0]}
                            </span>
                            <span className={styles.sizeItemDetail}>
                              {item.size}
                            </span>
                          </div>
                        )
                      })}
                  </div>
                </div>
                {product[`description_${lang.toLowerCase()}`] && (
                  <div className={styles.descriptionContainer}>
                    <h4 className={styles.descriptionTitle}>
                      {t('description')}
                    </h4>
                    <p className={styles.description}>
                      <Description
                        desc={product[
                          `description_${lang.toLowerCase()}`
                        ]?.replace(/"/g, '')}
                      />
                    </p>
                  </div>
                )}
                <div className={styles.orderContainer}>
                  <div className={styles.priceContainer}>
                    <p className={styles.price}>
                      {t('price')}:{' '}
                      {product[`price_${lang.toLowerCase()}`]
                        ? product[`price_${lang.toLowerCase()}`]
                        : ''}
                      {t('dram')}
                    </p>
                    {product?.oldPrice && (
                      <p className={styles.oldPrice}>
                        {product[`oldPrice_${lang.toLowerCase()}`]
                          ? product[`oldPrice_${lang.toLowerCase()}`]
                          : ''}
                        {t('oldPrice')}
                      </p>
                    )}
                  </div>
                  <button className={styles.orderButton} onClick={info}>
                    {t('order')}
                  </button>
                </div>
              </div>
              <div className={styles.optionsContainer}>
                <div className={styles.options}>
                  <table>
                    <h1 className={styles.optionsTitle}>{t('moreInfo')}</h1>
                    <tbody>
                      <tr>
                        <td className="leftTd">{t('categories')}</td>
                        <td className="rightTd">
                          {product.allCategories &&
                            product.allCategories
                              .slice(0, -1)
                              .map((item) => item.category + ', ')}
                          {product.allCategories &&
                            product.allCategories.length &&
                            product.allCategories[
                              product.allCategories.length - 1
                            ].category}
                        </td>
                      </tr>
                      <tr>
                        <td className="leftTd">{t('colors')}</td>
                        <td className="rightTd">
                          {product.colors &&
                            product.colors
                              .slice(0, -1)
                              .map((item) => item + ', ')}
                          {product.colors &&
                            product.colors.length &&
                            product.colors[product.colors.length - 1]}
                        </td>
                      </tr>
                      <tr>
                        <td className="leftTd">{t('materials')}</td>
                        <td className="rightTd">
                          {product.material &&
                            product.material
                              .slice(0, -1)
                              .map((item) => item.material + ', ')}
                          {product.material &&
                            product.material.length &&
                            product.material[product.material.length - 1]
                              .material}
                        </td>
                      </tr>
                      <tr>
                        <td className="leftTd">{t('decorativeElements')}</td>
                        <td className="rightTd">
                          {product.decorativeElement &&
                            product.decorativeElement
                              .slice(0, -1)
                              .map((item) => item.decorativeElement + ', ')}
                          {product.decorativeElement &&
                            product.decorativeElement.length &&
                            product.decorativeElement[
                              product.decorativeElement.length - 1
                            ].decorativeElement}
                        </td>
                      </tr>
                      <tr>
                        <td className="leftTd">
                          {product.season && product.season.length > 1
                            ? t('seasons')
                            : t('season')}
                        </td>
                        <td className="rightTd">
                          {product.season &&
                            product.season
                              .slice(0, -1)
                              .map((item) => item.season + ', ')}
                          {product.season &&
                            product.season.length &&
                            product.season[product.season.length - 1].season}
                        </td>
                      </tr>
                      <tr>
                        <td className="leftTd">{t('sleeves')}</td>
                        <td className="rightTd">
                          {' '}
                          {product.sleeve &&
                            product.sleeve
                              .slice(0, -1)
                              .map((item) => item.sleeveType + ', ')}
                          {product.sleeve &&
                            product.sleeve.length &&
                            product.sleeve[product.sleeve.length - 1]
                              .sleeveType}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Modal
        title={t('formingOrder')}
        open={open}
        okText={t('confirm')}
        // onOk={() => {
        //   setOrderLoading(true)
        // }}
        cancelText={t('cancel')}
        closable={false}
        okButtonProps={{
          style: { background: 'rgb(207, 75, 149)', color: 'white' },
        }}
        cancelButtonProps={{
          style: {
            border: 'rgb(207, 75, 149)',
            color: 'rgb(207, 75, 149)',
            boxShadow:
              'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
          },
        }}
        confirmLoading={confirmLoading}
        onOk={sendEmail}
        onCancel={() => {
          setOpen(false)
          setSendingValues({})
        }}>
        <div className={styles.createOrderContainer}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              requiredMarkValue: requiredMark,
            }}
            onValuesChange={onRequiredTypeChange}
            requiredMark={customizeRequiredMark}>
            <Form.Item
              label={t('fullName')}
              required
              tooltip={t('requiredField')}>
              <Input
                name="fullName"
                value={sendingValues.fullName}
                placeholder="Անուն Ազգանուն"
                allowClear={true}
                onChange={handleChange}
                style={{ borderColor: 'rgb(207, 75, 149)' }}
              />
            </Form.Item>
            <Form.Item label={t('email')} required tooltip={t('requiredField')}>
              <Input
                name="email"
                value={sendingValues.email}
                placeholder="էլ. հասցե"
                allowClear={true}
                onChange={handleChange}
                style={{ borderColor: 'rgb(207, 75, 149)' }}
              />
            </Form.Item>
            <Form.Item
              label={t('phoneNumber')}
              required
              tooltip={t('requiredField')}>
              <Input
                name="phoneNumber"
                value={sendingValues.phoneNumber}
                placeholder="+374 Ջեր համարը..."
                allowClear={true}
                onChange={handleChange}
                style={{ borderColor: 'rgb(207, 75, 149)' }}
              />
            </Form.Item>
            <div className={styles.sizeWrapper}>
              <div className={styles.sizeTitleWrapper}>
                <span className={styles.descriptionTitle}>{t('colors')}</span>
              </div>
              <div className={styles.sizeListContainer}>
                {productColors.length > 0 &&
                  productColors.map((item) => (
                    <div
                      key={item.id}
                      className={`${styles.colorItem} ${
                        sendingValues.activeColor &&
                        sendingValues.activeColor === item.color
                          ? styles.activeSize
                          : ''
                      }`}
                      onClick={() => handleChangeColor(item.color)}>
                      <span className={styles.colorItemTitle}>
                        {item.color}
                      </span>
                    </div>
                  ))}
              </div>
            </div>

            <div className={styles.sizeWrapper}>
              <div className={styles.sizeTitleWrapper}>
                <span className={styles.descriptionTitle}>{t('sizes')}</span>
              </div>
              <div className={styles.sizeListContainer}>
                {product?.size &&
                  product.size.map((item) => {
                    const parts = item.size.split('-')

                    return (
                      <div
                        key={item.id}
                        onClick={() =>
                          setSendingValues((prev) => ({
                            ...prev,
                            activeSize: item,
                          }))
                        }
                        className={`${styles.sizeItem} ${
                          sendingValues.activeSize?.size &&
                          sendingValues.activeSize.size === item.size
                            ? styles.activeSize
                            : ''
                        }`}>
                        <span className={styles.sizeItemTitle}>{parts[0]}</span>
                        <span className={styles.sizeItemDetail}>
                          {item.size}
                        </span>
                      </div>
                    )
                  })}
              </div>
            </div>

            <Form.Item label={t('orderMoreDetails')} required>
              <TextArea
                name="message"
                value={sendingValues.message}
                allowClear={true}
                onChange={handleChange}
                style={{ borderColor: 'rgb(207, 75, 149)' }}
                multiple
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Lightbox
        open={openLight}
        styles={{
          container: {
            backgroundColor: 'rgba(0, 0, 0, .5)',
          },
          navigationNext: {
            color: 'white',
          },
          button: {
            filter: 'none',
            fontSize: 25,
          },
        }}
        close={() => setOpenLight(false)}
        controller={{
          closeOnBackdropClick: true,
        }}
        slides={
          product?.imgs ? product.imgs.map((item) => ({ src: item.jpeg })) : []
        }
        render={{
          iconPrev: () => <KeyboardBackspaceIcon fontSize="large" />,
          iconNext: () => (
            <KeyboardBackspaceIcon
              style={{ transform: 'rotate(180deg)' }}
              fontSize="large"
            />
          ),
          iconClose: () => <AiOutlineClose />,
        }}
      />
    </>
  )
}
