import styles from './FilterPanel.module.css'
import { FaSearch } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import debounce from 'lodash.debounce'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { TbSearchOff } from 'react-icons/tb'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Button from '@mui/material/Button'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ProductCard from '../../../components/ProductCard/ProductCard'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { ToastContainer, toast } from 'react-toastify'
import Typography from '@mui/material/Typography'

AOS.init()

const minDistance = 100

export default function FilterPanel() {
  const [webpSupported, setWebpSupported] = useState(false)
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(2000000)
  const [products, setProducts] = useState([])
  const [colors, setColors] = useState([])
  const [categories, setCategories] = useState([
    {
      category: 'All',
    },
  ])
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('All')
  const [price, setPrice] = useState([min, max])
  const [color, setColor] = useState('')
  const [sizes, setSizes] = useState([])
  const [sleeves, setSleeves] = useState([])
  const [decorativeElements, setDecorativeElements] = useState([])
  const [seasons, setSeasons] = useState([])
  const [genders, setGenders] = useState([])
  const [materials, setMaterials] = useState([
    {
      material: 'All',
    },
  ])
  const [material, setMaterial] = useState('All')
  const [width, setWidth] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [currentItems, setCurrentItems] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 12
  const lang = useSelector((state) => state.lang.lang)
  const [activeCategory, setActiveCategory] = useState('')
  const [activeMaterial, setActiveMaterial] = useState('')
  const [activeColor, setActiveColor] = useState('')
  const [activeSize, setActiveSize] = useState('')
  const [activeSeason, setActiveSeason] = useState('')
  const [activeGender, setActiveGender] = useState('')
  const [activeSleeve, setActiveSleeve] = useState('')
  const [activeDecorativeElement, setActiveDecorativeElement] = useState('')

  const { t, i18n } = useTranslation()

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(products.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(products.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, products])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    )
    setItemOffset(newOffset)
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    document.title = `${t('products')} / Nelinka`
  }, [lang])

  useEffect(() => {
    document.title = `${t('products')} / Nelinka`

    const metaTagTitle = document.querySelector('meta[property="og:title"]')
    metaTagTitle.setAttribute('content', `${t('products')} / Nelinka`)
    const mataImage = document.querySelector('meta[property="og:image"]')
    mataImage.setAttribute('content', '/image/nelinkaIcon.svg')
    const metaDescription = document.querySelector(
      'meta[property="og:description"]'
    )
    metaDescription.setAttribute(
      'content',
      'Nelinka.am - մանկական և երեկոյան զգեստներ'
    )

    return () => {
      metaTagTitle.setAttribute('content', `${t('products')} / NELINKA`)
      mataImage.setAttribute('content', '/image/nelinkaIcon.svg')
      metaDescription.setAttribute(
        'content',
        'Nelinka.am - մանկական և երեկոյան զգեստներ'
      )
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [currentItems])

  const handleChangeColor = (itemColor) => {
    setColor(color !== itemColor ? itemColor : '')
  }

  function handleInputChange(e) {
    setSearch(e.target.value)
  }

  function handleRangeChange(e, newValue, activeThumb) {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], price[1] - minDistance)
        setPrice([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], price[0] + minDistance)
        setPrice([clamped - minDistance, clamped])
      }
    } else {
      setPrice(newValue)
    }
  }

  function handleResetFilter() {
    setCategory('All')
    setSearch('')
    setPrice([min, max])
    window.location.reload()
  }

  const parseProp = (item, lowerCaseLang, prop) => {
    return item[`${prop}_${lowerCaseLang}`]
      ? JSON.parse(item[`${prop}_${lowerCaseLang}`])
      : []
  }

  const fetchingPrices = debounce(async () => {
    try {
      const lowerCaseLanguage = lang.toLowerCase()

      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/productsNelinka`
      )
      const filteredDatas =
        res.data &&
        res.data
          .filter(
            (product) =>
              product[`price_${lowerCaseLanguage}`] >= price[0] &&
              product[`price_${lowerCaseLanguage}`] <= price[1]
          )
          .map((item) => {
            const imgs = parseProp(item, lowerCaseLanguage, 'imgs')
            const colors = parseProp(item, lowerCaseLanguage, 'colors')
            const material = parseProp(item, lowerCaseLanguage, 'material')
            const categories = parseProp(item, lowerCaseLanguage, 'category')
            const size = parseProp(item, lowerCaseLanguage, 'size')
            const sleeve = parseProp(item, lowerCaseLanguage, 'sleeve')
            const season = parseProp(item, lowerCaseLanguage, 'season')
            const gender = parseProp(item, lowerCaseLanguage, 'gender')
            const decorativeElement = parseProp(
              item,
              lowerCaseLanguage,
              'decorativeElement'
            )

            const categoryItem = categories.filter(
              (item) => item.lang === lang.toLowerCase()
            )

            const categoryValue =
              categoryItem.length > 0 ? categoryItem[0].category : null

            return {
              ...item,
              allCategories: categories,
              [`imgs_${lowerCaseLanguage}`]: imgs,
              [`colors_${lowerCaseLanguage}`]: colors,
              [`category_${lowerCaseLanguage}`]: category,
              [`material_${lowerCaseLanguage}`]: material,
              [`size_${lowerCaseLanguage}`]: size,
              [`sleeve_${lowerCaseLanguage}`]: sleeve,
              [`season_${lowerCaseLanguage}`]: season,
              [`gender_${lowerCaseLanguage}`]: gender,
              [`decorativeElement_${lowerCaseLanguage}`]: decorativeElement,
              categoryValue,
            }
          })
          .filter((item) => !item.disabled)

      console.log('filter datas ???')
      console.log(filteredDatas)
      setProducts(filteredDatas)
    } catch (error) {
      console.log(error)
    }
  }, 1000)

  useEffect(() => {
    const fetchSizes = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/sizesNelinka`
        )

        const filteredDatas =
          res.data.length && res.data.filter((item) => !item.disabled)

        setSizes(filteredDatas)
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchSizes()
  }, [])

  const fetchSleeves = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/sleevesNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => item.lang === lang.toLowerCase())

      setSleeves(filteredDatas)
    } catch (error) {
      toast.error(error.message)
    }
  }
  useEffect(() => {
    fetchSleeves()
  }, [])

  const fetchSeasons = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/seasonsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => item.lang === lang.toLowerCase())

      setSeasons(filteredDatas)
    } catch (error) {
      toast.error(error.message)
    }
  }
  useEffect(() => {
    fetchSeasons()
  }, [])

  const fetchDecorativeElements = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/decorativeElementsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => item.lang === lang.toLowerCase())

      setDecorativeElements(filteredDatas)
    } catch (error) {
      toast.error(error.message)
    }
  }
  useEffect(() => {
    fetchDecorativeElements()
  }, [])

  const fetchGenders = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/gendersNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => item.lang === lang.toLowerCase())

      setGenders(filteredDatas)
    } catch (error) {
      toast.error(error.message)
    }
  }
  useEffect(() => {
    fetchGenders()
  }, [])
  const fetchColors = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/colorsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => item.lang === lang.toLowerCase())

      setColors(filteredDatas)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    fetchColors()
  }, [])

  useEffect(() => {
    fetchingPrices()
    return () => fetchingPrices.cancel()
  }, [price])

  const fetchProducts = async () => {
    try {
      const lowerCaseLanguage = lang.toLowerCase()
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/productsNelinka`
      )
      const productsWithParsedJSON = res.data
        .map((item) => {
          const imgs = parseProp(item, lowerCaseLanguage, 'imgs')
          const colors = parseProp(item, lowerCaseLanguage, 'colors')
          const categories = parseProp(item, lowerCaseLanguage, 'category')
          const material = parseProp(item, lowerCaseLanguage, 'material')
          const size = parseProp(item, lowerCaseLanguage, 'size')
          const sleeve = parseProp(item, lowerCaseLanguage, 'sleeve')
          const season = parseProp(item, lowerCaseLanguage, 'season')
          const gender = parseProp(item, lowerCaseLanguage, 'gender')
          const decorativeElement = parseProp(
            item,
            lowerCaseLanguage,
            'decorativeElement'
          )

          const categoryItem = categories.filter(
            (item) => item.lang === lang.toLowerCase()
          )

          const categoryValue =
            categoryItem.length > 0 ? categoryItem[0].category : null

          return {
            ...item,
            allCategories: categories,
            [`imgs_${lowerCaseLanguage}`]: imgs,
            [`colors_${lowerCaseLanguage}`]: colors,
            [`category_${lowerCaseLanguage}`]: category,
            [`material_${lowerCaseLanguage}`]: material,
            [`size_${lowerCaseLanguage}`]: size,
            [`sleeve_${lowerCaseLanguage}`]: sleeve,
            [`season_${lowerCaseLanguage}`]: season,
            [`gender_${lowerCaseLanguage}`]: gender,
            [`decorativeElement_${lowerCaseLanguage}`]: decorativeElement,
            categoryValue,
          }
        })
        .filter((item) => !item.disabled)
      const prices = productsWithParsedJSON.map(
        (item) => item[`price_${lowerCaseLanguage}`]
      )
      const maxPrice = Math.max(...prices)
      const minPrice = Math.min(...prices)
      setMin(minPrice)
      setMax(maxPrice)

      console.log('PRODUCTS WITH PARSED JSON')
      console.log(productsWithParsedJSON)
      setProducts(productsWithParsedJSON)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchProducts()
  }, [])
  useEffect(() => {
    fetchProducts()
  }, [lang])

  const fetchCategories = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/categoriesNelinka`
      )
      const filteredCategories =
        res.data &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => item.lang === lang.toLowerCase())
      setCategories(filteredCategories)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchMaterials = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/materialsNelinka`
      )
      const filteredMaterials =
        res.data &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => item.lang === lang.toLowerCase())

      setMaterials(filteredMaterials)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    fetchMaterials()
  }, [])

  useEffect(() => {
    fetchMaterials()
    fetchCategories()
    fetchColors()
    fetchSeasons()
    fetchDecorativeElements()
    fetchGenders()
    fetchSleeves()
  }, [lang])

  function handleChange(e) {
    setCategory(e.target.value)
  }

  function handleChangeMaterial(e) {
    setMaterial(e.target.value)
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  console.log('FILTERED PRODUCTS')
  console.log(currentItems)
  console.log(activeColor)

  const filterProducts = () => {
    const filteredProducts =
      currentItems.length > 0 &&
      currentItems.filter((product) => {
        console.log('PRODUCER')
        console.log(product)
        const productName = product[`name_${lang.toLowerCase()}`]
        const productCategory = product.categoryValue
        const productMaterial = product[`material_${lang.toLowerCase()}`]
        const productColor = product[`colors_${lang.toLowerCase()}`]
        const productSize = product[`size_${lang.toLowerCase()}`]
        const productSeason = product[`season_${lang.toLowerCase()}`]
        const productSleeve = product[`sleeve_${lang.toLowerCase()}`]
        const productGender = product[`gender_${lang.toLowerCase()}`]
        const productDecorativeElements =
          product[`decorativeElement_${lang.toLowerCase()}`]

        if (
          search !== '' &&
          !productName.toLowerCase().includes(search.toLowerCase())
        ) {
          return false
        }

        if (activeCategory !== '' && productCategory !== activeCategory) {
          return false
        }

        if (
          activeMaterial !== '' &&
          !productMaterial?.find((item) => item.material === activeMaterial)
        ) {
          return false
        }

        if (
          activeColor !== '' &&
          !productColor?.find((item) => item.color === activeColor)
        ) {
          return false
        }

        if (
          activeSize !== '' &&
          !productSize?.find((item) => item.size === activeSize)
        ) {
          return false
        }

        if (
          activeSeason !== '' &&
          !productSeason?.find((item) => item.season === activeSeason)
        ) {
          return false
        }

        if (
          activeGender !== '' &&
          !productGender?.find((item) => item.gender === activeGender)
        ) {
          return false
        }

        if (
          activeSleeve !== '' &&
          !productSleeve?.find((item) => item.sleeveType === activeSleeve)
        ) {
          return false
        }

        if (
          activeDecorativeElement !== '' &&
          !productDecorativeElements?.find(
            (item) => item.decorativeElement === activeDecorativeElement
          )
        ) {
          return false
        }

        return true
      })

    if (filteredProducts.length > 0 && Array.isArray(filteredProducts)) {
      return filteredProducts.map((product, index) => (
        <ProductCard key={index} product={product} />
      ))
    } else {
      return (
        <div className={styles.cmp}>
          <div className={styles.containerNotFound}>
            <p className={styles.nothingFound}> {t('nothingFound')}</p>
            <span className={styles.ico}>
              <TbSearchOff className={styles.iconNotFound} />
            </span>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    if (
      activeCategory ||
      activeSize ||
      activeColor ||
      activeDecorativeElement ||
      activeSleeve ||
      activeMaterial ||
      activeSeason ||
      activeGender ||
      activeSleeve
    ) {
      if (currentItems?.length > 0) {
        console.log('FILTER FUNCTION')
        console.log(filterProducts())
      }
    }
  }, [
    activeCategory,
    activeSize,
    activeColor,
    activeDecorativeElement,
    activeSleeve,
    activeMaterial,
    activeSeason,
    activeGender,
    activeSleeve,
    search,
  ])

  return (
    <>
      <section
        viewport={{ amount: 0.2 }}
        initial="hidden"
        whileInView="visible"
        className={styles.section}>
        <div className={styles.crumbsContainer}>
          <div className={styles.crWrapper}>
            <div className={styles.crumbsWrapper}>
              <div role="presentation" className={styles.crumbsHeader}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" to="/">
                    {t('main')}
                  </Link>
                  <Typography color="black"> {t('products')}</Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mainWrapper}>
          <div className={styles.containerWrapper}>
            <div className={`${styles.container} ${styles.stretch}`}>
              <div className={styles.left}>
                <div className={styles.filterPanel} data-aos="fade-right">
                  <div className={styles.filterForm}>
                    <div className={styles.searchBar} data-aos="fade-up">
                      <input
                        type="text"
                        placeholder={`${t('search')}...`}
                        value={search}
                        onChange={handleInputChange}
                        className={styles.inpSearch}
                      />
                      <FaSearch className={styles.searchIcon} />
                    </div>

                    <label
                      className={styles.descriptionTitle}
                      data-aos="fade-up">
                      {t('price')}
                    </label>
                    <div className={styles.rangeContainer} data-aos="fade-up">
                      <div className={styles.filterPriceRange}>
                        <Box
                          sx={{
                            // width: width > 420 ? 350 : 200,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}>
                          <Slider
                            sx={{ color: 'rgb(207, 75, 149)' }}
                            getAriaLabel={() => 'Price range'}
                            value={price}
                            onChange={handleRangeChange}
                            valueLabelDisplay="auto"
                            disableSwap
                            min={min && min}
                            max={max && max}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>

                  <div className={styles.filterGroup}>
                    <div className={styles.sizeWrapper}>
                      <div className={styles.sizeTitleWrapper}>
                        <span className={styles.descriptionTitle}>
                          {t('categories')}
                        </span>
                      </div>
                      <div className={styles.sizeListContainer}>
                        {categories?.length > 0 &&
                          categories.map((item) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() => {
                                  if (activeCategory !== item.category) {
                                    setActiveCategory(item.category)
                                  } else {
                                    setActiveCategory('')
                                  }
                                }}
                                className={`${styles.sizeItem} ${
                                  activeCategory &&
                                  activeCategory === item.category
                                    ? styles.active
                                    : ''
                                }`}>
                                <span className={styles.sizeItemTitle}>
                                  {item.category}
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.filterGroup}>
                    <div className={styles.sizeWrapper}>
                      <div className={styles.sizeTitleWrapper}>
                        <span className={styles.descriptionTitle}>
                          {t('materials')}
                        </span>
                      </div>
                      <div className={styles.sizeListContainer}>
                        {materials?.length > 0 &&
                          materials.map((item) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() => {
                                  if (activeMaterial !== item.material) {
                                    setActiveMaterial(item.material)
                                  } else {
                                    setActiveMaterial('')
                                  }
                                }}
                                className={`${styles.sizeItem} ${
                                  activeMaterial &&
                                  activeMaterial === item.material
                                    ? styles.active
                                    : ''
                                }`}>
                                <span className={styles.sizeItemTitle}>
                                  {item.material}
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.filterGroup}>
                    <div className={styles.sizeWrapper}>
                      <div className={styles.sizeTitleWrapper}>
                        <span className={styles.descriptionTitle}>
                          {t('colors')}
                        </span>
                      </div>
                      <div className={styles.sizeListContainer}>
                        {colors?.length > 0 &&
                          colors.map((item) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() => {
                                  if (activeColor !== item.color) {
                                    setActiveColor(item.color)
                                  } else {
                                    setActiveColor('')
                                  }
                                }}
                                className={`${styles.sizeItem} ${
                                  activeColor && activeColor === item.color
                                    ? styles.active
                                    : ''
                                }`}>
                                <span className={styles.sizeItemTitle}>
                                  {item.color}
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.filterGroup}>
                    <div className={styles.sizeWrapper}>
                      <div className={styles.sizeTitleWrapper}>
                        <span className={styles.descriptionTitle}>
                          {t('sizes')}
                        </span>
                      </div>
                      <div className={styles.sizeListContainer}>
                        {sizes?.length > 0 &&
                          sizes.map((item) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() => {
                                  if (activeSize !== item.size) {
                                    setActiveSize(item.size)
                                  } else {
                                    setActiveSize('')
                                  }
                                }}
                                className={`${styles.sizeItem} ${
                                  activeSize && activeSize === item.size
                                    ? styles.active
                                    : ''
                                }`}>
                                <span className={styles.sizeItemTitle}>
                                  {item.size}
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.filterGroup}>
                    <div className={styles.sizeWrapper}>
                      <div className={styles.sizeTitleWrapper}>
                        <span className={styles.descriptionTitle}>
                          {t('seasons')}
                        </span>
                      </div>
                      <div className={styles.sizeListContainer}>
                        {seasons?.length > 0 &&
                          seasons.map((item) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() => {
                                  if (activeSeason !== item.season) {
                                    setActiveSeason(item.season)
                                  } else {
                                    setActiveSeason('')
                                  }
                                }}
                                className={`${styles.sizeItem} ${
                                  activeSeason && activeSeason === item.season
                                    ? styles.active
                                    : ''
                                }`}>
                                <span className={styles.sizeItemTitle}>
                                  {item.season}
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.filterGroup}>
                    <div className={styles.sizeWrapper}>
                      <div className={styles.sizeTitleWrapper}>
                        <span className={styles.descriptionTitle}>
                          {t('decorativeElements')}
                        </span>
                      </div>
                      <div className={styles.sizeListContainer}>
                        {decorativeElements?.length > 0 &&
                          decorativeElements.map((item) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() => {
                                  if (
                                    activeDecorativeElement !==
                                    item.decorativeElement
                                  ) {
                                    setActiveDecorativeElement(
                                      item.decorativeElement
                                    )
                                  } else {
                                    setActiveDecorativeElement('')
                                  }
                                }}
                                className={`${styles.sizeItem} ${
                                  activeDecorativeElement &&
                                  activeDecorativeElement ===
                                    item.decorativeElement
                                    ? styles.active
                                    : ''
                                }`}>
                                <span className={styles.sizeItemTitle}>
                                  {item.decorativeElement}
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.filterGroup}>
                    <div className={styles.sizeWrapper}>
                      <div className={styles.sizeTitleWrapper}>
                        <span className={styles.descriptionTitle}>
                          {t('genders')}
                        </span>
                      </div>
                      <div className={styles.sizeListContainer}>
                        {genders?.length > 0 &&
                          genders.map((item) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() => {
                                  if (activeGender !== item.gender) {
                                    setActiveGender(item.gender)
                                  } else {
                                    setActiveGender('')
                                  }
                                }}
                                className={`${styles.sizeItem} ${
                                  activeGender && activeGender === item.gender
                                    ? styles.active
                                    : ''
                                }`}>
                                <span className={styles.sizeItemTitle}>
                                  {item.gender}
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.filterGroup}>
                    <div className={styles.sizeWrapper}>
                      <div className={styles.sizeTitleWrapper}>
                        <span className={styles.descriptionTitle}>
                          {t('sleeves')}
                        </span>
                      </div>
                      <div className={styles.sizeListContainer}>
                        {sleeves?.length > 0 &&
                          sleeves.map((item) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() => {
                                  if (activeSleeve !== item.sleeveType) {
                                    setActiveSleeve(item.sleeveType)
                                  } else {
                                    setActiveSleeve('')
                                  }
                                }}
                                className={`${styles.sizeItem} ${
                                  activeSleeve &&
                                  activeSleeve === item.sleeveType
                                    ? styles.active
                                    : ''
                                }`}>
                                <span className={styles.sizeItemTitle}>
                                  {item.sleeveType}
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>

                  <div className={styles.filterButtonContainer}>
                    <Stack
                      sx={{ color: 'rgb(219, 128, 48)' }}
                      direction="row"
                      spacing={2}>
                      <Button
                        endIcon={<RotateLeftIcon />}
                        onClick={handleResetFilter}
                        variant="contained"
                        sx={{
                          color: '#fff',
                          backgroundColor: 'rgb(207, 75, 149)',
                          '&:hover': {
                            backgroundColor: 'rgba(207, 75, 149,0.9)',
                          },
                        }}>
                        {t('reset')}
                      </Button>
                    </Stack>
                  </div>
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.productsContainer}>
                  {filterProducts()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.paginationContainer}>
          <ReactPaginate
            breakLabel="..."
            nextLabel={<MdKeyboardArrowRight className={styles.nextLabel} />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel={
              <MdKeyboardArrowLeft className={styles.previousLabel} />
            }
            renderOnZeroPageCount={null}
            containerClassName={styles.pagination}
            pageLinkClassName={styles.pageNum}
            activeLinkClassName={styles.activePage}
            marginPagesDisplayed={1}
          />
        </div>
      </section>
    </>
  )
}
