import { useState, useEffect } from 'react'
import axios from 'axios'
import styles from './SaleItems.module.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Description from '../../pages/ProductPage/Description/Description'
import { TbCurrencyDram } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function SaleItems() {
  const [webpSupported, setWebpSupported] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const [saleItems, setSaleItems] = useState([])
  const lang = useSelector((state) => state.lang.lang)
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  }

  const parseProp = (item, lowerCaseLang, prop) => {
    return item[`${prop}_${lowerCaseLang}`]
      ? JSON.parse(item[`${prop}_${lowerCaseLang}`])
      : []
  }

  const fetchHiteProducts = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/productsNelinka`
      )
      const lowerCaseLanguage = lang.toLowerCase()

      const productsWithParsedJSON =
        res.data &&
        res.data
          .map((item) => {
            const imgs = parseProp(item, lowerCaseLanguage, 'imgs')
            const colors = parseProp(item, lowerCaseLanguage, 'colors')
            const category = parseProp(item, lowerCaseLanguage, 'category')

            const categoryItem = category.filter(
              (elem) => elem.lang === lowerCaseLanguage
            )

            const categoryValue =
              categoryItem.length > 0 ? categoryItem[0].category : null

            return {
              ...item,
              imgs,
              colors,
              category: categoryValue,
              allCategories: category,
              name: item[`name_${lang.toLowerCase()}`],
              price: item[`price_${lang.toLowerCase()}`],
              oldPrice: item[`oldPrice_${lang.toLowerCase()}`],
              description: item[`description_${lang.toLowerCase()}`],
            }
          })
          .filter((product) => product.status === 2)
          .filter((product) => product.oldPrice && +product.oldPrice > 0)
          .filter((product) => !product.disabled)

      console.log('PRODUCTS WITH PARSED JSON')
      console.log(productsWithParsedJSON)

      setSaleItems(productsWithParsedJSON.slice(0, 3))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchHiteProducts()
  }, [])

  useEffect(() => {
    fetchHiteProducts()
  }, [lang])

  // useEffect(() => {
  //   setSaleItems((prevProducts) =>
  //     prevProducts.map((product) => {
  //       const updatedCategories = product.allCategories
  //         .map((category) => {
  //           if (category.lang === lang.toLowerCase()) {
  //             return category
  //           }
  //           return null
  //         })
  //         .filter((category) => category !== null)

  //       return {
  //         ...product,
  //         category:
  //           updatedCategories.length > 0 ? updatedCategories[0].category : null,
  //       }
  //     })
  //   )
  // }, [lang])

  const { t, i18n } = useTranslation()

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <div>
      {saleItems.length > 0 && (
        <h1 className={styles.mainTitle}>{t('saleItems')} </h1>
      )}

      {saleItems.length > 0 && width >= 615 && (
        <section className={styles.section}>
          <div className={`${styles.container} ${styles.saleItemsContainer}`}>
            <div
              onClick={() =>
                navigate(`/allProducts/product/${saleItems[0]?.id}`)
              }
              style={{ cursor: 'pointer' }}
              className={styles.left}>
              <div className={styles.imgContainer}>
                <img
                  src={
                    webpSupported
                      ? saleItems[0]?.imgs[0].webp
                      : saleItems[0]?.imgs[0].webp
                  }
                  alt={saleItems[0]?.name}
                  className={styles.mainImg}
                />
              </div>
              <div className={styles.info}>
                <h1 className={styles.title}>{saleItems[0]?.name}</h1>
                <p className={styles.description}>
                  {<Description desc={saleItems[0].description} />}
                </p>
                <div className={styles.prices}>
                  <p className={styles.newPrice}>
                    <TbCurrencyDram /> {saleItems[0]?.price}
                  </p>
                  <p className={styles.oldPrice}>
                    <TbCurrencyDram />
                    {saleItems[0]?.oldPrice}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  navigate(`/allProducts/product/${saleItems[1]?.id}`)
                }
                className={`${styles.miniProducts} ${styles.first}`}>
                <div className={styles.discaundTag}>
                  -
                  {Math.round(
                    ((+saleItems[1]?.oldPrice - +saleItems[1]?.price) /
                      +saleItems[1]?.oldPrice) *
                      100
                  )}
                  %
                </div>
                <img
                  src={
                    webpSupported
                      ? saleItems[1]?.imgs[0].webp
                      : saleItems[1]?.imgs[0].webp
                  }
                  alt={saleItems[1]?.name}
                  className={styles.img}
                />
                <h2 className={styles.name}>{saleItems[1]?.name}</h2>
                <p className={styles.type}>{saleItems[1]?.category}</p>
              </div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  navigate(`/allProducts/product/${saleItems[2]?.id}`)
                }
                className={styles.miniProducts}>
                <div className={styles.discaundTag}>
                  -
                  {Math.round(
                    ((+saleItems[2]?.oldPrice - +saleItems[2]?.price) /
                      +saleItems[2]?.oldPrice) *
                      100
                  )}
                  %
                </div>
                <img
                  src={
                    webpSupported
                      ? saleItems[2]?.imgs[0].webp
                      : saleItems[2]?.imgs[0].webp
                  }
                  alt={saleItems[2]?.name}
                  className={styles.img}
                />
                <h2 className={styles.name}>{saleItems[2]?.name}</h2>
                <p className={styles.type}>{saleItems[2]?.category}</p>
              </div>
            </div>
          </div>
        </section>
      )}
      {width < 615 && (
        <div className={styles.containerSlider}>
          <div className={styles.sliderGrid}>
            <Slider {...settings}>
              <div className={styles.miniProducts}>
                <div className={styles.discaundTag}>
                  {Math.round(
                    ((+saleItems[0]?.oldPrice - +saleItems[0]?.price) /
                      +saleItems[0]?.oldPrice) *
                      100
                  )}
                  %
                </div>
                <img
                  src={
                    webpSupported
                      ? saleItems[0]?.imgs[0].webp
                      : saleItems[0]?.imgs[0].webp
                  }
                  alt="Chair 1"
                  className={styles.img}
                />
                <h2 className={styles.name}>{saleItems[0]?.name}</h2>
                <p className={styles.type}>{saleItems[0]?.category}</p>
              </div>

              <div className={styles.miniProducts}>
                <div className={styles.discaundTag}>
                  -
                  {Math.round(
                    ((+saleItems[1]?.oldPrice - +saleItems[1]?.price) /
                      +saleItems[1]?.oldPrice) *
                      100
                  )}
                  %
                </div>
                <img
                  src={
                    webpSupported
                      ? saleItems[1]?.imgs[0].webp
                      : saleItems[1]?.imgs[0].webp
                  }
                  alt="Chair 1"
                  className={styles.img}
                />
                <h2 className={styles.name}>{saleItems[1]?.name}</h2>
                <p className={styles.type}>{saleItems[1]?.category}</p>
              </div>

              <div className={styles.miniProducts}>
                <div className={styles.discaundTag}>
                  -
                  {Math.round(
                    ((+saleItems[2]?.oldPrice - +saleItems[2]?.price) /
                      +saleItems[2]?.oldPrice) *
                      100
                  )}
                  %
                </div>
                <img
                  src={
                    webpSupported
                      ? saleItems[2]?.imgs[0].webp
                      : saleItems[2]?.imgs[0].webp
                  }
                  alt="Chair 1"
                  className={styles.img}
                />
                <h2 className={styles.name}>{saleItems[2]?.name}</h2>
                <p className={styles.type}>{saleItems[2]?.category}</p>
              </div>
            </Slider>
          </div>
        </div>
      )}
    </div>
  )
}
