import styles from './Main.module.css'
import MainSlider from '../Slider/Slider'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import { useState, useEffect } from 'react'
import axios from 'axios'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Loader from '../Loader/Loader'
import Description from '../../pages/ProductPage/Description/Description'
import { useNavigate, Link } from 'react-router-dom'
import React from 'react'

export default function Main() {
  const navigate = useNavigate()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const lang = useSelector((state) => state.lang.lang)
  const [width, setWidth] = useState(window.innerWidth)
  const [baners, setBaners] = useState([])
  const [loading, setLoading] = useState(false)
  const [main, setMain] = useState([])
  const [webpSupported, setWebpSupported] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleNavigateToAllProducts() {
    navigate('/allProducts')
  }

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/banersNelinka`
        )
        const datasParser =
          res.data &&
          res.data.map((item) => {
            return {
              id: item.id,
              img: JSON.parse(item.img),
            }
          })

        setBaners(datasParser)
      } catch (error) {
        console.log(error)
      }
    }
    fetchBanners()
  }, [])

  useEffect(() => {
    const fetchMain = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/mainNelinka`
        )
        const filteredData =
          res.data &&
          res.data.filter((item) => item.lang === lang.toLowerCase())

        setMain(filteredData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchMain()
  }, [lang])

  useEffect(() => {
    const fetchMain = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/mainNelinka`
        )
        const filteredData =
          res.data &&
          res.data.filter((item) => item.lang === lang.toLowerCase())

        setMain(filteredData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchMain()
  }, [])

  // useEffect(() => {
  //   async function handleLogout() {
  //     try {
  //       await axios.put(`${process.env.REACT_APP_SERVER_API}/isAdminNelinka`, {
  //         isAdmin: 0,
  //       })
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }

  //   handleLogout()
  // }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    touchMove: true,
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <>
      {loading && <Loader />}
      <section className={styles.section1}>
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.mainContent}>
              {main &&
                main.map((item) => (
                  <div key={item.id}>
                    <div key={item.id} className={styles.title}>
                      {item?.title}
                    </div>
                    <p className={styles.desc}>
                      <Description desc={item?.description.replace(/"/g, '')} />
                    </p>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.rightContainer}>
              {width > 768 && <MainSlider />}
              {width < 768 && (
                <Slider {...settings}>
                  {baners.length !== 0 &&
                    baners.map((image, index) => (
                      <div key={index}>
                        <img
                          onClick={handleNavigateToAllProducts}
                          src={
                            webpSupported ? image?.img?.webp : image?.img?.jpeg
                          }
                          alt={`Baner No ${index + 1}`}
                          className={styles.adaptiveSliderImg}
                        />
                      </div>
                    ))}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
