import styles from './EditAboutUs.module.css'
import { Editor } from '@tinymce/tinymce-react'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { v4 as uuid } from 'uuid'
import axios from 'axios'
import { AiOutlineCloudUpload, AiOutlineClose } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { toast, ToastContainer } from 'react-toastify'
import storage from '../../firebase'
import imageCompression from 'browser-image-compression'

export default function EditAbout() {
  const navigate = useNavigate()
  const [content, setContent] = useState('')
  const [files, setFiles] = useState(null)
  const [description, setDescription] = useState('')
  const { pathname } = useLocation()
  const [lang, setLang] = useState('')
  const [aboutImg, setAboutImg] = useState(null)
  const [img, setImg] = useState(null)
  const [webpSupported, setWebpSupported] = useState(false)

  const onInputChange = (e) => {
    setFiles(e.target.files[0])
  }

  const aboutId = pathname.split('/')[5]

  console.log(img)

  async function sendingClick(e) {
    e.preventDefault()

    try {
      await axios
        .put(
          `${process.env.REACT_APP_SERVER_API}/editAboutNelinka/${aboutId}`,
          {
            description: description,
            img: img !== null ? JSON.stringify(img) : null,
          }
        )
        .then(() => {
          toast.success(
            `${
              lang === 'arm'
                ? 'Armenia'
                : lang === 'rus'
                ? 'Russian'
                : lang === 'eng' && 'English'
            } About has been edit successfully !`
          )
        })
        .catch((err) => toast.error(err.message))

      setTimeout(() => {
        navigate('/admin/admindashboard/aboutUs')
      }, 3000)
    } catch (error) {
      console.log(error)
    }
  }
  async function onSubmit() {
    if (files === null) return
    const newName = files.name + uuid()

    try {
      const compressedImage = await imageCompression(files, {
        maxSize: 500 * 1024,
        maxWidthOrHeight: 800,
      })

      const jpegImageRef = ref(storage, `images/about/${newName}.jpeg`)
      await uploadBytes(jpegImageRef, compressedImage).then(() =>
        console.log('Jpeg has been edited !')
      )

      const webpImage = await createImageBitmap(files)
      const webpCanvas = document.createElement('canvas')
      webpCanvas.width = webpImage.width
      webpCanvas.height = webpImage.height
      const webpCanvasContext = webpCanvas.getContext('2d')
      webpCanvasContext.drawImage(webpImage, 0, 0)
      webpCanvas.toBlob(
        async (webpBlob) => {
          if (webpBlob) {
            const webpImageRef = ref(storage, `images/about/${newName}.webp`)
            await uploadBytes(webpImageRef, webpBlob).then(() =>
              toast.success('Նկարը հաջող փոփոխվել է !')
            )

            const jpegDownloadURL = await getDownloadURL(jpegImageRef)
            const webpDownloadURL = await getDownloadURL(webpImageRef)
            setImg({ jpeg: jpegDownloadURL, webp: webpDownloadURL })
          }
        },
        'image/webp',
        0.9
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log(img)
  }, [img])

  function handleSubmit(e) {
    e.preventDefault()
    onSubmit()
  }
  useEffect(() => {
    const container = document.createElement('p')

    container.innerHTML = content

    const descriptionElement = container.querySelector('p')
    const descriptionAm = descriptionElement && descriptionElement.outerHTML

    setDescription(descriptionAm)
  }, [content])

  const handleEditorChange = (content, editor) => {
    setDescription(content)
  }

  const handleFilePicker = (callback, value, meta) => {
    if (meta.filetype === 'image') {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/jpeg')
      input.onchange = function () {
        const file = this.files[0]
        callback(`/image/main/${file.name}`)
      }
      input.click()
    }
  }

  useEffect(() => {
    const fetchAbout = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/aboutNelinka`
        )
        console.log(res.data)
        const filteredAbout =
          res.data &&
          res.data
            .filter((item) => +item.id === +aboutId)
            .map((item) => {
              const img = JSON.parse(item?.img)

              return {
                img,
                description: item?.description,
                lang: item?.lang,
                id: item?.id,
              }
            })

        setDescription(filteredAbout[0].description)
        setAboutImg(filteredAbout[0].img)
        setLang(filteredAbout[0].lang)
      } catch (error) {
        console.log(error)
      }
    }
    fetchAbout()
  }, [])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <div>
      <h1 className={styles.title}></h1>
      <Editor
        apiKey="03r0cavlstl2k64nsmewzfymzwdhtinowah10xlnklhwn7tv"
        value={description && description}
        onEditorChange={handleEditorChange}
        init={{
          height: 500,
          plugins: 'link image code',
          toolbar:
            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | code',
          automatic_uploads: true,
          file_picker_types: 'image',
          file_picker_callback: handleFilePicker,
        }}
      />

      <div className={styles.imgContainerWrapper}>
        {' '}
        <div className={styles.container}>
          <input
            type="file"
            id="file"
            accept="image/*"
            hidden
            onChange={onInputChange}
          />
          <div
            className={`${styles.imgArea} ${img ? styles.active : ''}`}
            data-img="">
            {img ? (
              <div>
                <img
                  src={webpSupported ? img?.webp : img?.jpeg}
                  alt="Selected"
                />
                <AiOutlineClose className={styles.deleteImg} />
              </div>
            ) : (
              <div>
                <img
                  src={webpSupported ? aboutImg?.webp : aboutImg?.jpeg}
                  alt="Selected"
                />
                <AiOutlineClose className={styles.deleteImg} />
              </div>
            )}
          </div>
          <div className={styles.sendBtnContainer}>
            <Stack spacing={2} direction="row">
              <Button onClick={handleSubmit} variant="outlined">
                Ուղարկել Նկարը
              </Button>
            </Stack>
          </div>
          <button
            className={styles.selectImage}
            onClick={() => document.querySelector('#file').click()}>
            Ընտրել Նկար
          </button>
        </div>
        <div className={styles.sendBtnContainer}>
          <Stack direction="row" spacing={2}>
            <Button type="button" onClick={sendingClick} variant="contained">
              Հաստատել Ձևափոխումը
            </Button>
          </Stack>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
