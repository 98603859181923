import WeAre from '../../components/WeAre/WeAre'
import Main from '../../components/Main/Main'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Contacts from '../../components/Contacts/Contacts'
import SaleItems from '../../components/SaleItems/SaleItems'
import Products from '../../components/Products/Products'
import { useEffect } from 'react'
import ProductsHeader from '../../components/ProductsHeader/ProductsHeader'

export default function MainWrapper() {
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    document.title = 'Nelinka.am - մանկական և երեկոյան զգեստներ'

    const metaTagTitle = document.querySelector('meta[property="og:title"]')
    metaTagTitle.setAttribute(
      'content',
      'Nelinka.am - մանկական և երեկոյան զգեստներ'
    )
    const mataImage = document.querySelector('meta[property="og:image"]')
    mataImage.setAttribute('content', '/image/nelinkaIcon.svg')
    const metaDescription = document.querySelector(
      'meta[property="og:description"]'
    )
    metaDescription.setAttribute(
      'content',
      'Nelinka.am - մանկական և երեկոյան զգեստներ'
    )

    return () => {
      metaTagTitle.setAttribute(
        'content',
        'Nelinka.am - մանկական և երեկոյան զգեստներ'
      )
      mataImage.setAttribute('content', '/image/nelinkaIcon.svg')
      metaDescription.setAttribute(
        'content',
        'Nelinka.am - մանկական և երեկոյան զգեստներ'
      )
    }
  }, [])

  return (
    <>
      <Header />
      <div style={{ display: 'none' }}>
        <ProductsHeader />
      </div>
      <Main />
      <WeAre />
      <Products />
      <SaleItems />
      <Contacts />
      <Footer />
    </>
  )
}
