import DataTable from 'react-data-table-component'
import { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import styles from './AdminDecorativeElements.module.css'
import TextField from '@mui/material/TextField'
import SendIcon from '@mui/icons-material/Send'
import axios from 'axios'
import Switch from '@mui/material/Switch'
import { IconButton, Button as MuiButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/system'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../Loader/Loader'
import { Modal as AntDModal } from 'antd'
// import { Input } from '@chakra-ui/react'
import 'react-toastify/dist/ReactToastify.css'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: -10,
  p: 4,
}
export default function AdminDecorativeElements() {
  const [sendCategoryLoading, setSendCategoryLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [open, setOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [productsCategories, setProductsCategories] = useState([])
  const [value, setValue] = useState({
    decorativeElement: '',
    disabled: 0,
    lang: '',
  })
  const [loading, setLoading] = useState(true)
  const StyledIconButton = styled(IconButton)({
    '&:hover': {
      color: 'red',
    },
  })

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  const handleInputChange = (e) => {
    setValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setValue({ decorativeElement: '', disabled: 0, lang: '' })
  }

  async function handleSend(e) {
    e.preventDefault()

    if (value.decorativeElement === '') {
      return toast.info(
        "You can't add a new decorative element because the field doesn't have data"
      )
    }
    setSendCategoryLoading(true)
    try {
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_API}/addDecorativeElementNelinka`,
          value
        )
        .then(({ data }) => {
          toast.success(data, {
            toastClassName: styles.myToast,
          })
          setSendCategoryLoading(false)
          setOpen(false)
          setValue({ decorativeElement: '', disabled: 0, lang: '' })
          window.location.reload()
        })
    } catch (error) {
      toast.error(error.message)
      setSendCategoryLoading(false)
    }
  }

  const handleRowClicked = (row) => {
    setItemId(row.id)
  }

  const handleSwitchChange = (id) => (e) => {
    const value = e.target.checked ? 1 : 0
    setIsChecked(value)
    setItemId(id)
    try {
      axios.put(
        `${process.env.REACT_APP_SERVER_API}/editDecorativeElementNelinka`,
        {
          isChecked: value,
          id: id,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setLoading(true)
    const fetchGetCategories = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/decorativeElementsNelinka`
        )
        setProductsCategories(res.data)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    fetchGetCategories()
  }, [])

  async function handleDeleteCategory(id) {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_API}/deleteDecorativeElementNelinka/${id}`
      )
      const updatedCategories = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/decorativeElementsNelinka`
      )
      setProductsCategories(updatedCategories.data)
    } catch (error) {
      console.log(error)
    }
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Դեկորատիվ Էլեմենտ',
      selector: (row) => row.decorativeElement,
      sortable: true,
    },
    {
      name: 'Լեզու',
      selector: (row) => (row.lang ? row.lang : 'No Language'),
      sortable: true,
    },

    {
      name: 'Անջատված',
      selector: (row) => (
        <div>
          {row.disabled ? (
            <Switch
              {...label}
              defaultChecked
              onChange={handleSwitchChange(row.id)}
            />
          ) : (
            <Switch {...label} onChange={handleSwitchChange(row.id)} />
          )}
        </div>
      ),
    },
    {
      name: 'Գործողություններ',
      selector: (row) => (
        <Stack direction="row" spacing={1}>
          <StyledIconButton aria-label="delete">
            <DeleteIcon
              color="error"
              onClick={(e) => {
                handleRowClicked(row)
                showModal()
              }}
            />
          </StyledIconButton>
        </Stack>
      ),
    },
  ]

  return (
    <>
      <DataTable
        title="Դեկորատիվ Էլեմենտների ցանկ"
        columns={columns}
        data={productsCategories}
        fixedHeader
        fixedHeaderScrollHeight="450px"
        pagination
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        onRowClicked={handleRowClicked}
        actions={
          <div>
            <Stack spacing={2} direction="row">
              <MuiButton
                onClick={handleOpen}
                variant="outlined"
                color="inherit"
                style={{
                  border: 'solid rgba(207, 75, 149,0.9)',
                  color: 'rgb(207, 75, 149)',
                }}>
                + Ավելացնել Նոր Դեկորատիվ Էլեմենտ
              </MuiButton>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className={styles.titleModal}>
                    Ստեղծել նոր դեկորատիվ էլեմենտ
                  </Typography>
                  <Box
                    className={styles.addInpCateogoryContainer}
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                      id="standard-basic"
                      label="Նոր դեկորատիվ էլեմենտի անվանումը"
                      value={value.decorativeElement}
                      variant="standard"
                      color="primary"
                      name="decorativeElement"
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box
                    className={styles.addInpCateogoryContainer}
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                      id="standard-basic"
                      label="Լեզու"
                      value={value.lang}
                      variant="standard"
                      color="primary"
                      name="lang"
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box className={styles.sendButtonContainer}>
                    <Box sx={{ '& > button': { m: 1 } }}>
                      <LoadingButton
                        onClick={handleSend}
                        endIcon={<SendIcon />}
                        loading={sendCategoryLoading}
                        loadingPosition="end"
                        variant="contained"
                        color="inherit"
                        style={{
                          backgroundColor: 'rgba(207, 75, 149,0.9)',
                          color: 'white',
                        }}>
                        <span>Ստեղծել</span>
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Stack>
            <ToastContainer toastClassName={styles.myToast} />
          </div>
        }
      />
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDeleteCategory(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      {loading && <Loader />}
    </>
  )
}
