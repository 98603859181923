import styles from './EditProductPage.module.css'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'
import { MdAddCircle } from 'react-icons/md'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { TbFlag3Filled } from 'react-icons/tb'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import { IoIosPricetag } from 'react-icons/io'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Editor } from '@tinymce/tinymce-react'
import DescriptionIcon from '@mui/icons-material/Description'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { v4 as uuidv4 } from 'uuid'
import { AiFillPicture } from 'react-icons/ai'
import Switch from '@mui/material/Switch'
import { GiSightDisabled } from 'react-icons/gi'
import RefreshIcon from '@mui/icons-material/Refresh'
import { TbRefreshDot } from 'react-icons/tb'
import storage from '../../firebase'
import {
  uploadBytes,
  ref,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { toast, ToastContainer } from 'react-toastify'
import imageCompression from 'browser-image-compression'
import { Select as AntSelect, Tag } from 'antd'
import Button from '@mui/material/Button'
import { Button as AntdButton, Dropdown } from 'antd'
import { BarLoader } from 'react-spinners'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: -10,
  p: 4,
}

const tagRender = (props) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color: 'white',
        background: 'rgb(207, 75, 149)',
        display: 'flex',
        alignItems: 'center',
      }}>
      {label}
    </Tag>
  )
}

const tagRenderMaterial = (props) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color: 'white',
        background: 'rgb(207, 75, 149)',
        display: 'flex',
        alignItems: 'center',
      }}>
      {label}
    </Tag>
  )
}

export default function AddProductPage() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [webpSupported, setWebpSupported] = useState(false)
  const [files, setFiles] = useState(null)
  const [content, setContent] = useState('')
  const [open, setOpen] = useState('')
  const [options, setOptions] = useState([])
  const [materials, setMaterials] = useState([])
  const [colors, setColors] = useState([])
  const [sizes, setSizes] = useState([])
  const [sleeves, setSleeves] = useState([])
  const [decorativeElements, setDecorativeElements] = useState([])
  const [genders, setGenders] = useState([])
  const [seasons, setSeasons] = useState([])
  const [product, setProduct] = useState([])
  const [defaultMaterials, setDefaultMaterials] = useState([])
  const [defaultCategories, setDefaultCategories] = useState([])
  const [defaultSizes, setDefaultSize] = useState([])
  const [defaultSeasons, setDefaultSeasons] = useState([])
  const [defaultDecorativeElements, setDefaultDecorativeElements] = useState([])
  const [defaultSleeves, setDefaultSleeves] = useState([])
  const [defaultGenders, setDefaultGenders] = useState([])
  const [defaultsColors, setDefaultsColors] = useState([])
  const [addCategory, setAddCategory] = useState({
    category: '',
    disabled: 0,
  })
  const [addMaterial, setAddMaterial] = useState({
    material: '',
    disabled: 0,
  })
  const [sendingValues, setSendingValues] = useState({})
  const [sendingCategories, setSendingCategories] = useState([])
  const [sendingMaterials, setSendingMaterials] = useState([])
  const [sendingColors, setSendingColors] = useState([])
  const [sendingDecorativeElements, setSendingDecorativeElements] = useState([])
  const [sendingSizes, setSendingSizes] = useState([])
  const [sendingGenders, setSendingGenders] = useState([])
  const [sendingSleeves, setSendingSleeves] = useState([])
  const [sendingSeasons, setSendingSeasons] = useState([])
  const fileInputRef = useRef(null)
  const [language, setLanguage] = useState('arm')
  const [imgLoader, setImgLoader] = useState(false)
  const [languageItems, setLanguageItems] = useState([
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" onClick={() => setLanguage('arm')}>
          Հայերեն
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a rel="noopener noreferrer" onClick={() => setLanguage('rus')}>
          Ռուսերեն
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a rel="noopener noreferrer" onClick={() => setLanguage('eng')}>
          Անգլերեն
        </a>
      ),
    },
  ])

  useEffect(() => {
    if (product.id) {
      setSendingValues({
        name: product[`name_${language}`] ? product[`name_${language}`] : '',
        status: product.status,
        price: product[`price_${language}`] ? product[`price_${language}`] : '',
        category:
          product[`category_${language}`].length > 0
            ? product[`category_${language}`]
            : [],
        material:
          product[`material_${language}`].length > 0
            ? product[`material_${language}`]
            : [],
        colors:
          product[`colors_${language}`].length > 0
            ? product[`colors_${language}`]
            : [],
        imgs:
          product[`imgs_${language}`].length > 0
            ? product[`imgs_${language}`]
            : [],
        description: product[`description_${language}`]
          ? product[`description_${language}`]
          : '',
        height: product[`height_${language}`]
          ? product[`height_${language}`]
          : '',
        width: product[`width_${language}`] ? product[`width_${language}`] : '',
        disabled: product.disabled,
        oldPrice: product[`oldPrice_${language}`]
          ? product[`oldPrice_${language}`]
          : 0,
        depth: product[`depth_${language}`] ? product[`depth_${language}`] : '',
        size:
          product[`size_${language}`].length > 0
            ? product[`size_${language}`]
            : [],
        decorativeElement:
          product[`decorativeElement_${language}`].length > 0
            ? product[`decorativeElement_${language}`]
            : [],
        season:
          product[`season_${language}`].length > 0
            ? product[`season_${language}`]
            : [],
        sleeve:
          product[`sleeve_${language}`].length > 0
            ? product[`sleeve_${language}`]
            : [],
        gender:
          product[`gender_${language}`].length > 0
            ? product[`gender_${language}`]
            : [],
      })

      console.log('SET DEFAULT COLORS')
      console.log(product[`colors_${language}`])

      setDefaultsColors(
        product[`colors_${language}`].length > 0
          ? product[`colors_${language}`].map((color) => color.color)
          : []
      )
    }
  }, [product])

  const productId = pathname.split('/')[4]

  const fetchProducts = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/productsNelinka`
      )

      const productsWithParsedJSON =
        res.data &&
        res.data
          .filter((item) => item.id === +productId)
          .map((item) => {
            const imgs = item[`imgs_${language}`]
              ? JSON.parse(item[`imgs_${language}`])
              : []
            const colors = item[`colors_${language}`]
              ? JSON.parse(item[`colors_${language}`])
              : []
            const materials = item[`material_${language}`]
              ? JSON.parse(item[`material_${language}`])
              : []
            const category = item[`category_${language}`]
              ? JSON.parse(item[`category_${language}`])
              : []
            const size = item[`size_${language}`]
              ? JSON.parse(item[`size_${language}`])
              : []
            const sleeve = item[`sleeve_${language}`]
              ? JSON.parse(item[`sleeve_${language}`])
              : []
            const season = item[`season_${language}`]
              ? JSON.parse(item[`season_${language}`])
              : []
            const decorativeElement = item[`decorativeElement_${language}`]
              ? JSON.parse(item[`decorativeElement_${language}`])
              : []
            const gender = item[`gender_${language}`]
              ? JSON.parse(item[`gender_${language}`])
              : []

            return {
              ...item,
              [`imgs_${language}`]: imgs,
              [`colors_${language}`]: colors,
              [`material_${language}`]: materials,
              [`category_${language}`]: category,
              [`size_${language}`]: size,
              [`season_${language}`]: season,
              [`decorativeElement_${language}`]: decorativeElement,
              [`sleeve_${language}`]: sleeve,
              [`gender_${language}`]: gender,
            }
          })

      console.log('products with parsed')
      console.log(productsWithParsedJSON)

      setDefaultMaterials(
        productsWithParsedJSON[0][`material_${language}`].length > 0
          ? productsWithParsedJSON[0][`material_${language}`].map(
              (item) => item.material
            )
          : []
      )
      setDefaultCategories(
        productsWithParsedJSON[0][`category_${language}`].length > 0
          ? productsWithParsedJSON[0][`category_${language}`].map(
              (item) => item.category
            )
          : []
      )
      setSendingMaterials(
        productsWithParsedJSON[0][`material_${language}`].length > 0
          ? productsWithParsedJSON[0][`material_${language}`].map(
              (item) => item.material
            )
          : []
      )
      setSendingCategories(
        productsWithParsedJSON[0][`category_${language}`].length > 0
          ? productsWithParsedJSON[0][`category_${language}`].map(
              (item) => item.category
            )
          : []
      )

      setSendingColors(
        productsWithParsedJSON[0][`colors_${language}`].length > 0
          ? productsWithParsedJSON[0][`colors_${language}`].map(
              (item) => item.color
            )
          : []
      )

      setDefaultSeasons(
        productsWithParsedJSON[0][`season_${language}`].length > 0
          ? productsWithParsedJSON[0][`season_${language}`].map(
              (item) => item.season
            )
          : []
      )
      setSendingSeasons(
        productsWithParsedJSON[0][`season_${language}`].length > 0
          ? productsWithParsedJSON[0][`season_${language}`].map(
              (item) => item.season
            )
          : []
      )
      setDefaultDecorativeElements(
        productsWithParsedJSON[0][`decorativeElement_${language}`].length > 0
          ? productsWithParsedJSON[0][`decorativeElement_${language}`].map(
              (item) => item.decorativeElement
            )
          : []
      )
      setSendingDecorativeElements(
        productsWithParsedJSON[0][`decorativeElement_${language}`].length > 0
          ? productsWithParsedJSON[0][`decorativeElement_${language}`].map(
              (item) => item.decorativeElement
            )
          : []
      )
      setDefaultSleeves(
        productsWithParsedJSON[0][`sleeve_${language}`].length > 0
          ? productsWithParsedJSON[0][`sleeve_${language}`].map(
              (item) => item.sleeveType
            )
          : []
      )
      setSendingSleeves(
        productsWithParsedJSON[0][`sleeve_${language}`].length > 0
          ? productsWithParsedJSON[0][`sleeve_${language}`].map(
              (item) => item.sleeveType
            )
          : []
      )
      setDefaultSize(
        productsWithParsedJSON[0][`size_${language}`].length > 0
          ? productsWithParsedJSON[0][`size_${language}`].map(
              (item) => item.size
            )
          : []
      )
      setSendingSizes(
        productsWithParsedJSON[0][`size_${language}`].length > 0
          ? productsWithParsedJSON[0][`size_${language}`].map(
              (item) => item.size
            )
          : []
      )

      setDefaultGenders(
        productsWithParsedJSON[0][`gender_${language}`].length > 0
          ? productsWithParsedJSON[0][`gender_${language}`].map(
              (item) => item.gender
            )
          : []
      )
      setSendingGenders(
        productsWithParsedJSON[0][`gender_${language}`].length > 0
          ? productsWithParsedJSON[0][`gender_${language}`].map(
              (item) => item.gender
            )
          : []
      )

      console.log('sleeve colors')
      console.log(productsWithParsedJSON[0][`sleeve_${language}`])

      setProduct(productsWithParsedJSON[0])
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchCategories = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/categoriesNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data
          .filter(
            (item) =>
              !item.disabled &&
              item.lang.toLowerCase() === language.toLowerCase()
          )
          .map((item) => {
            return { value: item.category }
          })

      setOptions(filteredDatas ? filteredDatas : [])
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchMaterials = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/materialsNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data
          .filter(
            (item) =>
              !item.disabled &&
              item.lang.toLowerCase() === language.toLowerCase()
          )
          .map((item) => {
            return {
              value: item.material,
            }
          })
      setMaterials(filteredDatas ? filteredDatas : [])
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchSeasons = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/seasonsNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data
          .filter(
            (item) =>
              !item.disabled &&
              item.lang.toLowerCase() === language.toLowerCase()
          )
          .map((item) => {
            return {
              value: item.season,
            }
          })
      setSeasons(filteredDatas ? filteredDatas : [])
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchDecorativeElements = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/decorativeElementsNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data
          .filter(
            (item) =>
              !item.disabled &&
              item.lang.toLowerCase() === language.toLowerCase()
          )
          .map((item) => {
            return {
              value: item.decorativeElement,
            }
          })
      setDecorativeElements(filteredDatas ? filteredDatas : [])
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchGenders = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/gendersNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data
          .filter(
            (item) =>
              !item.disabled &&
              item.lang.toLowerCase() === language.toLowerCase()
          )
          .map((item) => {
            return {
              value: item.gender,
            }
          })
      setGenders(filteredDatas ? filteredDatas : [])
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchSleeves = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/sleevesNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data
          .filter(
            (item) =>
              !item.disabled &&
              item.lang.toLowerCase() === language.toLowerCase()
          )
          .map((item) => {
            return {
              value: item.sleeveType,
            }
          })
      setSleeves(filteredDatas ? filteredDatas : [])
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchColors = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/colorsNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data.filter(
          (item) =>
            !item.disabled && item.lang.toLowerCase() === language.toLowerCase()
        )

      if (Array.isArray(filteredDatas) && filteredDatas.length > 0) {
        const changedDatas = filteredDatas.map((item) => ({
          label: item.color,
          value: item.color.toLowerCase(),
          color: item.color,
        }))

        setColors(changedDatas)
        //   filteredDatas.map((item) =>
        //   setColors((prev) => [
        //     ...prev,
        //     {
        //       label: item.color,
        //       value: item.color.toLowerCase(),
        //       color: item.color,
        //     },
        //   ])
        // )
      } else {
        setColors([])
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  useEffect(() => {
    setSendingValues([])
    fetchProducts()
    fetchColors()
    fetchCategories()
    fetchMaterials()
    fetchDecorativeElements()
    fetchSeasons()
    fetchSleeves()
    fetchGenders()
  }, [language])

  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  const handleDeleteImage = (img) => {
    const imageRefJpeg = ref(storage, img.jpeg)
    const imageRefWebp = ref(storage, img.webp)

    deleteObject(imageRefJpeg).then(() =>
      console.log('Jpeg has been delete sucess')
    )
    deleteObject(imageRefWebp).then(() =>
      console.log('Webp has been elete success')
    )

    setSendingValues((prev) => ({
      ...prev,
      imgs: [
        ...sendingValues.imgs.filter((filteredImg) => filteredImg !== img),
      ],
    }))
  }

  const handleEditorChange = (content, editor) => {
    setSendingValues((prev) => ({ ...prev, description: content }))
  }

  const handleChangeMaterial = (selectedMaterial) => {
    setSendingMaterials([...selectedMaterial])
  }
  const handleChangeSize = (selectedMaterial) => {
    setSendingSizes([...selectedMaterial])
  }
  const handleChangeDecorativeElement = (selectedMaterial) => {
    setSendingDecorativeElements([...selectedMaterial])
  }
  const handleChangeSleeve = (selectedMaterial) => {
    setSendingSleeves([...selectedMaterial])
  }
  const handleChangeSeason = (selectedMaterial) => {
    setSendingSeasons([...selectedMaterial])
  }
  const handleChangeGender = (selectedMaterial) => {
    setSendingGenders([...selectedMaterial])
  }

  const handleChangeCategory = (selectedCategory) => {
    setSendingCategories([...selectedCategory])
  }
  const handleChangeColor = (selectedColor) => {
    setSendingColors([...selectedColor])
  }

  const handleSendValues = async (e) => {
    e.preventDefault()

    let categoriesArray = []
    let materialsArray = []
    let colorsArray = []
    let sizesArray = []
    let decorativeElementsArray = []
    let sleevesArray = []
    let seasonsArray = []
    let gendersArray = []
    const url = `${process.env.REACT_APP_SERVER_API}/editProductNelinka/${language}/${productId}`

    if (Array.isArray(sendingCategories) && sendingCategories.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/categoriesNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sendingCategories.includes(item.category))

      categoriesArray = filteredDatas
    }

    if (Array.isArray(sendingMaterials) && sendingMaterials.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/materialsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sendingMaterials.includes(item.material))

      materialsArray = filteredDatas
    }
    if (Array.isArray(sendingColors) && sendingColors.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/colorsNelinka`
      )
      const lowerCase = sendingColors.map((item) => item.toLowerCase())

      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => lowerCase.includes(item.color.toLowerCase()))
          .map((item) => {
            return { color: item.color, label: item.color, value: item.color }
          })

      colorsArray = filteredDatas
    }
    if (Array.isArray(sendingSizes) && sendingSizes.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/sizesNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sendingSizes.includes(item.size))

      sizesArray = filteredDatas
    }
    if (
      Array.isArray(sendingDecorativeElements) &&
      sendingDecorativeElements.length > 0
    ) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/decorativeElementsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) =>
            sendingDecorativeElements.includes(item.decorativeElement)
          )

      decorativeElementsArray = filteredDatas
    }
    if (Array.isArray(sendingSeasons) && sendingSeasons.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/seasonsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sendingSeasons.includes(item.season))

      seasonsArray = filteredDatas
    }
    if (Array.isArray(sendingSleeves) && sendingSleeves.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/sleevesNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sendingSleeves.includes(item.sleeveType))

      sleevesArray = filteredDatas
    }
    if (Array.isArray(sendingGenders) && sendingGenders.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/gendersNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sendingGenders.includes(item.gender))

      gendersArray = filteredDatas
    }

    try {
      await axios
        .put(url, {
          ...sendingValues,
          material: JSON.stringify(materialsArray),
          category: JSON.stringify(categoriesArray),
          colors: JSON.stringify(colorsArray),
          imgs: sendingValues.imgs.length && JSON.stringify(sendingValues.imgs),
          size: JSON.stringify(sizesArray),
          sleeve: JSON.stringify(sleevesArray),
          season: JSON.stringify(seasonsArray),
          decorativeElement: JSON.stringify(decorativeElementsArray),
          gender: JSON.stringify(gendersArray),
        })
        .then(() => {
          toast.success('Product has been edited successfully!')

          setTimeout(() => {
            navigate('/admin/admindashboard/products')
          }, 1000)
        })
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    const container = document.createElement('p')

    container.innerHTML = content

    const descriptionElement = container.querySelector('p')
    const description = descriptionElement && descriptionElement.outerHTML

    setSendingValues((prev) => ({ ...prev, description: description }))
  }, [content])

  const onInputChange = (e) => {
    setFiles(e.target.files)
  }

  async function uploadImgProduct() {
    let newFileNames = []
    let downloadURLs = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const newFileName = uuidv4() + '.' + file.name.split('.').pop()
      newFileNames.push(newFileName)
      setImgLoader(true)

      const jpegImageRef = ref(
        storage,
        `images/testProducts/${newFileName}.jpeg`
      )
      const webpImageRef = ref(
        storage,
        `images/testProducts/${newFileName}.webp`
      )

      try {
        const compressedImage = await imageCompression(file, {
          maxSize: 500 * 1024,
          maxWidthOrHeight: 800,
        })

        const jpegCompressedImage = await imageCompression(compressedImage, {
          fileType: 'jpeg',
        })

        const webpCompressedImage = await imageCompression(compressedImage, {
          fileType: 'webp',
        })

        await uploadBytes(jpegImageRef, jpegCompressedImage)
        await uploadBytes(webpImageRef, webpCompressedImage)

        const jpegDownloadURL = await getDownloadURL(jpegImageRef)
        const webpDownloadURL = await getDownloadURL(webpImageRef)

        const imgObj = { jpeg: jpegDownloadURL, webp: webpDownloadURL }
        downloadURLs.push(imgObj)
      } catch (error) {
        toast.error(error.message)
      }
    }

    setSendingValues((prev) => ({
      ...prev,
      imgs: [...prev.imgs, ...downloadURLs],
    }))
    setImgLoader(false)
  }

  useEffect(() => {
    console.log('FILES FILES')
    console.log(files)

    if (files) {
      uploadImgProduct()
    }
  }, [files])

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    fetchMaterials()
  }, [])

  useEffect(() => {
    const fetchSizes = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/sizesNelinka`
        )
        const filteredDatas =
          res.data.length &&
          res.data
            .filter((item) => !item.disabled)
            .map((item) => {
              return {
                value: item.size,
              }
            })

        setSizes(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchSizes()
  }, [])

  useEffect(() => {
    fetchSeasons()
  }, [])

  useEffect(() => {
    fetchDecorativeElements()
  }, [])
  useEffect(() => {
    fetchGenders()
  }, [])
  useEffect(() => {
    fetchSleeves()
  }, [])

  useEffect(() => {
    fetchColors()
  }, [])

  const handleSwitchChange = (e) => {
    setSendingValues((prev) => ({
      ...prev,
      disabled: prev.disabled ? 0 : 1,
    }))
  }

  const handleFilePicker = (callback, value, meta) => {
    if (meta.filetype === 'image') {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/jpeg')
      input.onchange = function () {
        const file = this.files[0]
        callback(`/image/main/${file.name}`)
      }
      input.click()
    }
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  console.log('DEFAULTS COLORS')
  console.log(defaultsColors)
  console.log(defaultSeasons)

  return (
    <section className={styles.section}>
      <div className={styles.containerHeader}>
        <header className={styles.header}>
          <div className={styles.leftHeader}>
            <h1>Ապրանքի փոփողություն</h1>
          </div>
          <div className={styles.rightHeader}>
            <div className={styles.backContainer}>
              <Breadcrumbs aria-label="breadcrumb">
                <RouterLink to="/admin/admindashboard/products">
                  <div className={styles.backContainer}>
                    <HiOutlineArrowNarrowLeft className={styles.backIcon} />
                    հետ գնալ
                  </div>
                </RouterLink>
                <button onClick={handleSendValues} className={styles.btn}>
                  <MdAddCircle
                    onClick={handleSendValues}
                    className={styles.iconBtn}
                  />
                  Փոփողել
                </button>
              </Breadcrumbs>
            </div>
          </div>
        </header>
      </div>
      <div className={styles.container}>
        <div className={styles.disabledContainer}>
          <Dropdown
            menu={{
              items: languageItems,
            }}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}>
            <AntdButton>Լեզու</AntdButton>
          </Dropdown>
          <div className={styles.disabled}>
            <label className={styles.disabledLabel}>
              <GiSightDisabled className={styles.disabledIcon} />
              Անջատված:
            </label>
            <Switch
              {...label}
              checked={sendingValues.disabled === 1}
              onChange={handleSwitchChange}
            />
          </div>
        </div>
        <div className={styles.formNameContainer}>
          <label className={styles.labelName}>
            <TbFlag3Filled className={styles.productNameIcon} />
            Ապրանքի անվանում:
          </label>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}>
            <TextField
              label="Name"
              value={!sendingValues.name ? '' : sendingValues.name}
              onChange={(e) =>
                setSendingValues((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              id="outlined-size-small"
              size="small"
              fullWidth
            />
          </Box>
        </div>
        <div className={styles.statusContainer}>
          <label className={styles.labelStatus}>
            <MilitaryTechIcon />
            Ստատուս:
          </label>
          <div
            className={`${styles.status_es} ${
              sendingValues?.status === 1 && styles.statusRef
            }`}
            onClick={() =>
              setSendingValues((prev) => ({ ...prev, status: 1 }))
            }>
            <Box
              sx={{
                width: 'auto',
                minWidth: 100,
                paddingRight: 1,
                paddingLeft: 1,
              }}>
              <Grid
                container
                justifyContent="center"
                className={styles.statusIconContainer}>
                <Tooltip title="Hite" placement="top">
                  <WhatshotIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 1 }))
                    }
                    className={styles.statusIcon}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 1 }))
                  }>
                  Հիթ
                </span>
              </Grid>
            </Box>
          </div>
          <div
            className={`${styles.status_es} ${
              sendingValues?.status === 2 && styles.statusRef
            }`}
            onClick={() =>
              setSendingValues((prev) => ({ ...prev, status: 2 }))
            }>
            <Box
              sx={{
                width: 'auto',
                minWidth: 100,
                paddingRight: 1,
                paddingLeft: 1,
              }}>
              <Grid
                container
                justifyContent="center"
                className={styles.statusIconContainer}>
                <Tooltip title="News" placement="top">
                  <NewspaperIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 2 }))
                    }
                    className={styles.statusIcon}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 2 }))
                  }>
                  Նորություն
                </span>
              </Grid>
            </Box>
          </div>
          {/* <div
            className={`${styles.status_es} ${
              sendingValues?.status === 3 && styles.statusRef
            }`}
            onClick={() =>
              setSendingValues((prev) => ({ ...prev, status: 3 }))
            }>
            <Box sx={{    width: 'auto',
                  minWidth: 100,
                  paddingRight: 1,
                  paddingLeft: 1, }}>
              <Grid
                container
                justifyContent="center"
                className={styles.statusIconContainer}>
                <Tooltip title="Soon" placement="top">
                  <StarIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 3 }))
                    }
                    className={styles.statusIcon}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 3 }))
                  }>
                  Soon
                </span>
              </Grid>
            </Box>
          </div>
          <div
            className={`${styles.status_es} ${
              sendingValues.status === 4 && styles.statusRef
            }`}
            onClick={() =>
              setSendingValues((prev) => ({ ...prev, status: 4 }))
            }>
            <Box sx={{    width: 'auto',
                  minWidth: 100,
                  paddingRight: 1,
                  paddingLeft: 1, }}>
              <Grid
                container
                justifyContent="center"
                className={styles.statusIconContainer}>
                <Tooltip title="Recent Project" placement="top">
                  <NewReleasesIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 4 }))
                    }
                    className={styles.statusIcon}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 4 }))
                  }>
                  Recent
                </span>
              </Grid>
            </Box>
          </div> */}
          <div
            className={`${styles.status_es} ${
              sendingValues?.status === 0 && styles.statusRef
            }`}
            onClick={() =>
              setSendingValues((prev) => ({ ...prev, status: 0 }))
            }>
            <Box
              sx={{
                width: 'auto',
                minWidth: 100,
                paddingRight: 1,
                paddingLeft: 1,
              }}>
              <Grid
                container
                justifyContent="center"
                className={`${styles.statusIconContainer} ${styles.refreshContainer}`}>
                <Tooltip title="Reset" placement="top">
                  <RefreshIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 0 }))
                    }
                    className={`${styles.statusIcon} ${styles.restart}`}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 0 }))
                  }>
                  Reset
                </span>
              </Grid>
            </Box>
          </div>
        </div>

        <div className={styles.priceContainer}>
          <label className={styles.labelPrice}>
            <IoIosPricetag className={styles.priceIcon} />
            Գին:
          </label>
          <TextField
            id="standard-textarea"
            label="Price"
            value={!sendingValues.price ? '' : sendingValues.price}
            onChange={(e) =>
              setSendingValues((prev) => ({ ...prev, price: e.target.value }))
            }
            placeholder="Enter product price"
            multiline
            variant="standard"
          />
        </div>
        <div className={styles.priceContainer}>
          <label className={styles.labelOldPrice}>
            <TbRefreshDot className={styles.oldPrice} />
            Հին Գին. Պարտադիր չէ
          </label>
          <TextField
            id="standard-textarea"
            label="Old Price"
            value={!sendingValues.oldPrice ? '' : sendingValues.oldPrice}
            onChange={(e) =>
              setSendingValues((prev) => ({
                ...prev,
                oldPrice: e.target.value,
              }))
            }
            placeholder="Enter old price"
            multiline
            variant="standard"
          />
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Category:</label>
          {sendingValues.category && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRenderMaterial}
              onChange={handleChangeCategory}
              style={{
                width: '100%',
              }}
              options={options}
              defaultValue={defaultCategories}
            />
          )}
        </div>
        <div className={styles.colorsContainer}>
          <label className={styles.colorsLable}>Գույներ:</label>
          {sendingValues.colors && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRender}
              onChange={handleChangeColor}
              style={{
                width: '100%',
              }}
              options={colors}
              defaultValue={defaultsColors}
            />
          )}
        </div>

        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Մատերիալներ:</label>
          {sendingValues.material && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRenderMaterial}
              onChange={handleChangeMaterial}
              style={{
                width: '100%',
              }}
              options={materials}
              defaultValue={defaultMaterials}
            />
          )}
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Չափսեր:</label>
          {sendingValues.size && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRenderMaterial}
              onChange={handleChangeSize}
              style={{
                width: '100%',
              }}
              options={sizes}
              defaultValue={defaultSizes}
            />
          )}
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Սեզոններ:</label>
          {sendingValues.season && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRenderMaterial}
              onChange={handleChangeSeason}
              style={{
                width: '100%',
              }}
              options={seasons}
              defaultValue={defaultSeasons}
            />
          )}
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Դեկորատիվ Էլեմենտներ:</label>
          {sendingValues.decorativeElement && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRenderMaterial}
              onChange={handleChangeDecorativeElement}
              style={{
                width: '100%',
              }}
              options={decorativeElements}
              defaultValue={defaultDecorativeElements}
            />
          )}
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Սեռ:</label>
          {sendingValues.gender && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRenderMaterial}
              onChange={handleChangeGender}
              style={{
                width: '100%',
              }}
              options={genders}
              defaultValue={defaultGenders}
            />
          )}
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Թևի տեսակ:</label>
          {sendingValues.sleeve && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRenderMaterial}
              onChange={handleChangeSleeve}
              style={{
                width: '100%',
              }}
              options={sleeves}
              defaultValue={defaultSleeves}
            />
          )}
        </div>
        <div className={styles.editorContainer}>
          <label className={styles.descriptionLabel}>
            <DescriptionIcon />
            Նկարագրություն:
          </label>
          <Editor
            apiKey="03r0cavlstl2k64nsmewzfymzwdhtinowah10xlnklhwn7tv"
            value={sendingValues.description && sendingValues.description}
            onEditorChange={handleEditorChange}
            init={{
              height: 500,
              plugins: 'link image code',
              toolbar:
                'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | code',
              automatic_uploads: true,
              file_picker_types: 'image',
              file_picker_callback: handleFilePicker,
            }}
          />
        </div>
        <form>
          <div className={styles.imgUploaderContainer}>
            <div className={styles.lableImgContainer}>
              <label className={styles.imgLabel}>
                <AiFillPicture className={styles.imgIcon} />
                Նկարներ:
              </label>
            </div>
            <div className={styles.imgWrapper}>
              <input
                onChange={onInputChange}
                type="file"
                id="file"
                accept="image/*"
                hidden
                multiple
                ref={fileInputRef}
              />
              <div className={styles.imgArea} data-img="">
                <CloudUploadIcon className={styles.uploadImgIcon} />
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  fileInputRef?.current?.click()
                }}
                className={styles.selectImage}>
                Ընտել Նկարները
              </button>
            </div>
          </div>
          {imgLoader && <BarLoader width="100%" color="rgb(207, 75, 149)" />}
        </form>
        {sendingValues.imgs && sendingValues?.imgs?.length ? (
          <div className={styles.imgContainer}>
            {sendingValues.imgs.map((img) => (
              <div key={uuidv4()} className={styles.imgWrapper}>
                <img
                  className={styles.img}
                  src={webpSupported ? img?.webp : img?.jpeg}
                  alt="Sending"
                />
                <div
                  className={styles.deleteBtn}
                  onClick={() => handleDeleteImage(img)}></div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {/* </div> */}
      <ToastContainer />
    </section>
  )
}
