import DataTable from 'react-data-table-component'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Home from '@mui/icons-material/Home'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import StarIcon from '@mui/icons-material/Star'
import styles from './ProductsTable.module.css'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
// import Button from '@mui/material/Button'
import axios from 'axios'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/system'
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import Loader from '../../Loader/Loader'
import { Modal as AntDModal } from 'antd'
import { ref, deleteObject } from 'firebase/storage'
import storage from '../../../firebase'
import { createTheme } from '@mui/material/styles'
import { Button, Input } from 'antd'

const { Search } = Input

export default function ProductsTable() {
  const [preloader, setPreLoader] = useState(true)
  const [test, setTest] = useState([])
  const [products, setProducts] = useState([])
  const [webpSupported, setWebpSupported] = useState(false)
  const [filteredProducts, setFilteredProducts] = useState([])
  const [search, setSearch] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [status, setStatus] = useState('0')
  const [homeStatus, setHomeStatus] = useState(false)
  const [saleItemsStatus, setSaleItemsStatus] = useState(false)
  const StyledIconButton = styled(IconButton)({
    '&:hover': {
      color: 'rgb(207, 75, 149)',
    },
  })

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  const handleSwitchChange = (id) => (e) => {
    const value = e.target.checked ? 1 : 0

    setIsChecked(value)
    try {
      axios.put(
        `${process.env.REACT_APP_SERVER_API}/editproductdisabledNelinka`,
        {
          isChecked: value,
          id: id,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }
  const handleChangeStatus = (id, newStatus) => {
    if (homeStatus && newStatus !== 0) return alert("You can't do that acion ")
    try {
      axios.put(
        `${process.env.REACT_APP_SERVER_API}/editProductStatusNelinka`,
        {
          newStatus: newStatus,
          id: id,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeStatusSellItems = (id, newStatus) => {
    if (saleItemsStatus && newStatus !== 0)
      return alert("You can't do that action ")
    try {
      axios.put(
        `${process.env.REACT_APP_SERVER_API}/editProductStatusNelinka`,
        {
          newStatus: newStatus,
          id: id,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  async function handleDeleteCategory(info) {
    info.imgs.map((item) => {
      const imageRefJpeg = ref(storage, item.jpeg)
      const imageRefWebp = ref(storage, item.webp)

      deleteObject(imageRefJpeg).then(() =>
        console.log('Jpeg has been delete sucess')
      )
      deleteObject(imageRefWebp).then(() =>
        console.log('Webp has been elete success')
      )
    })
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_API}/deleteproductNelinka/${info.itemId}`
      )
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/productsNelinka`
      )
      const productsWithParsedJSON = res.data.map((item) => {
        const imgs = JSON.parse(item.imgs_arm)
        const colors = JSON.parse(item.colors_arm)
        const materials = JSON.parse(item.material_arm)

        return {
          ...item,
          imgs_arm: imgs,
          colors_arm: colors,
          material_arm: materials,
        }
      })
      setProducts(productsWithParsedJSON)
    } catch (error) {
      console.log(error)
    }
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Ապրանք',
      selector: (row) => (
        <div className={styles.tableProductColumn}>
          <img
            style={{ height: '40px', margin: '10px' }}
            src={
              webpSupported ? row?.imgs_arm[0]?.webp : row?.imgs_arm[0]?.jpeg
            }
            alt="Image"
            onClick={() => setTest(row)}
          />
          <Link to="?">{row.name_arm}</Link>
        </div>
      ),
    },
    {
      name: 'Գույներ',
      selector: (row) => (
        <div>
          {row &&
            Array.isArray(row.colors_arm) &&
            row.colors_arm.length > 0 &&
            row.colors_arm.slice(0, -1).map((item) => item.color + ', ')}
          {row &&
            row.colors_arm.length &&
            row.colors_arm[row.colors_arm.length - 1].color}
        </div>
      ),
    },
    {
      name: 'Չափսեր',
      selector: (row) => (
        <div>
          {row &&
            row.size_arm &&
            Array.isArray(row.size_arm) &&
            row.size_arm.length > 0 &&
            row.size_arm?.slice(0, -1).map((item) => item.size + ', ')}
          {row &&
            row.size_arm &&
            row.size_arm.length &&
            row.size_arm[row.size_arm.length - 1].size}
        </div>
      ),
    },
    {
      name: 'Սեզոններ',
      selector: (row) => (
        <div>
          {row &&
            Array.isArray(row.season_arm) &&
            row.season_arm.length > 0 &&
            row.season_arm.slice(0, -1).map((item) => item.season + ', ')}
          {row &&
            row.season_arm.length &&
            row.season_arm[row.season_arm.length - 1].season}
        </div>
      ),
    },
    {
      name: 'Դեկորատիվ էլեմենտներ',
      selector: (row) => (
        <div>
          {row &&
            Array.isArray(row.decorativeElement_arm) &&
            row.decorativeElement_arm.length > 0 &&
            row.decorativeElement_arm
              .slice(0, -1)
              .map((item) => item.decorativeElement + ', ')}
          {row &&
            row.decorativeElement_arm.length &&
            row.decorativeElement_arm[row.decorativeElement_arm.length - 1]
              .decorativeElement}
        </div>
      ),
    },
    {
      name: 'Թևի Տեսակներ',
      selector: (row) => (
        <div>
          {row &&
            Array.isArray(row.sleeve_arm) &&
            row.sleeve_arm.length > 0 &&
            row.sleeve_arm.slice(0, -1).map((item) => item.sleeveType + ', ')}
          {row &&
            row.sleeve_arm.length &&
            row.sleeve_arm[row.sleeve_arm.length - 1].sleeveType}
        </div>
      ),
    },
    {
      name: 'Գին',
      selector: (row) => row.price_arm,
      sortable: true,
    },
    {
      name: 'Զեղչված գին',
      selector: (row) => (
        <div>{row.oldPrice_arm ? row.oldPrice_arm : <p>Don't have</p>}</div>
      ),
      sortable: true,
    },
    {
      name: 'Կատեգորիա',
      selector: (row) => (
        <div>
          {row &&
            row.category_arm &&
            Array.isArray(row.category_arm) &&
            row.category_arm.length > 0 &&
            row?.category_arm?.slice(0, -1).map((item) => item.category + ', ')}
          {row &&
            row.category_arm?.length &&
            row.category_arm[row.category_arm.length - 1].category}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Մատերիալ',
      selector: (row) => (
        <div>
          {row &&
            Array.isArray(row.material_arm) &&
            row.material_arm.length > 0 &&
            row.material_arm.slice(0, -1).map((item) => item.material + ', ')}
          {row &&
            row.material_arm.length &&
            row.material_arm[row.material_arm.length - 1].material}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Ստատուս',
      selector: (row) => (
        <div className={styles.statusCont}>
          <StyledIconButton
            onClick={() => {
              handleChangeStatus(row.id, row.status === 1 ? 0 : 1)
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }}
            aria-label="delete">
            <Home
              color="inherit"
              style={{
                color: row.status === 1 ? 'rgba(207, 75, 149,0.9)' : 'gray',
              }}
            />
          </StyledIconButton>
          <StyledIconButton
            onClick={() => {
              handleChangeStatusSellItems(row.id, row.status === 2 ? 0 : 2)
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }}
            aria-label="delete">
            <NewspaperIcon
              color="inherit"
              style={{
                color: row.status === 2 ? 'rgba(207, 75, 149,0.9)' : 'gray',
              }}
            />
          </StyledIconButton>
        </div>
      ),
    },
    {
      name: 'Անջատված',
      selector: (row) => (
        <div>
          {row.disabled ? (
            <Switch
              {...label}
              defaultChecked
              onChange={handleSwitchChange(row.id)}
            />
          ) : (
            <Switch {...label} onChange={handleSwitchChange(row.id)} />
          )}
        </div>
      ),
    },
    {
      name: 'Գործողություններ',
      selector: (row) => (
        <>
          <StyledIconButton
            onClick={(e) => {
              setItemId({ itemId: row.id, imgs: row.imgs_arm })
              showModal()
            }}
            aria-label="delete">
            <DeleteIcon color="error" />
          </StyledIconButton>
          <StyledIconButton aria-label="delete">
            <IconButton
              component={Link}
              to={`/admin/admindashboard/editProduct/${row.id}`}>
              <CreateIcon color="warning" />
            </IconButton>
          </StyledIconButton>
        </>
      ),
    },
  ]

  const fetchProducts = async () => {
    setPreLoader(true)
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/productsNelinka`
      )
      const homeStatusLength =
        res.data && res.data.filter((item) => +item.status === 1)
      const sellStatusLength =
        res.data && res.data.filter((item) => +item.status === 2)

      homeStatusLength.length === 4 ? setHomeStatus(true) : setHomeStatus(false)
      sellStatusLength.length === 3
        ? setSaleItemsStatus(true)
        : setSaleItemsStatus(false)
      const productsWithParsedJSON = res.data.map((item) => {
        const imgs = JSON.parse(item.imgs_arm)
        const colors = JSON.parse(item.colors_arm)
        const materials = JSON.parse(item.material_arm)
        const category = JSON.parse(item.category_arm)
        const size = JSON.parse(item.size_arm)
        const sleeve = JSON.parse(item.sleeve_arm)
        const season = JSON.parse(item.season_arm)
        const decorativeElement = JSON.parse(item.decorativeElement_arm)

        return {
          ...item,
          imgs_arm: imgs,
          colors_arm: colors,
          material_arm: materials,
          category_arm: category,
          size_arm: size,
          sleeve_arm: sleeve,
          season_arm: season,
          decorativeElement_arm: decorativeElement,
        }
      })
      setPreLoader(false)
      setProducts(productsWithParsedJSON)
    } catch (error) {
      console.log(error)
      setPreLoader(false)
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  useEffect(() => {
    const filtered = products?.filter((product) =>
      product?.name?.toLowerCase().includes(search?.toLowerCase())
    )
    setFilteredProducts(filtered)
  }, [search, products])

  useEffect(() => {
    setFilteredProducts(products)
  }, [])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  console.log('PRODUCTS')
  console.log(products)

  return (
    <div style={{ overflow: 'auto' }}>
      <DataTable
        title="Ապրանքների Ցանկը"
        columns={columns}
        data={search !== '' ? filteredProducts : products}
        fixedHeader
        fixedHeaderScrollHeight="450px"
        pagination
        selectableRowsHighlight
        highlightOnHover
        style={{ width: '1800px' }}
        selectableRowsNoSelectAll
        subHeaderWrap
        customStyles={{
          tableWrapper: {
            overflowX: 'auto',
          },
        }}
        actions={
          <div>
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                size="middle"
                style={{ border: '1px solid rgb(207, 75, 149)' }}>
                <Link to="/admin/admindashboard/addproduct">
                  Ավելացնել Ապրանք
                </Link>
              </Button>
            </Stack>
          </div>
        }
        subHeader
        subHeaderComponent={
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off">
            <Search
              activeBorderColor="rgb(207, 75, 149)"
              hoverBorderColor="rgb(207, 75, 149)"
              placeholder="Որոնել որևէ ապրանք"
              value={search}
              style={{ width: 300 }}
              color="red"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        }
        subHeaderAlign="center"
      />
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDeleteCategory(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      {preloader && <Loader />}
    </div>
  )
}
