import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiTimeFive } from 'react-icons/bi'
import { FiPhone } from 'react-icons/fi'
import { IoLocationOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { setContactUs } from '../../store/sections/sectionsAction'
import styles from './Contacts.module.css'

export default function Contacts() {
  const lang = useSelector((state) => state.lang.lang)
  const dispatch = useDispatch()
  const contactRef = useRef(null)
  const [contact, setContact] = useState([])

  const { t, i18n } = useTranslation()
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    if (contactRef.current) {
      dispatch(setContactUs(contactRef))
    }
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      const contactValue = contactRef.current.offsetTop

      localStorage.setItem('contact', contactValue)
    }, 1000)
  }, [contactRef?.current?.offsetTop])

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/contactNelinka`
        )

        console.log('RES CONTACT')
        console.log(res)

        const filteredDatas =
          res.data &&
          res.data.filter((item) => item.lang === lang.toLowerCase())

        setContact(filteredDatas[0])
      } catch (error) {
        console.log(error)
      }
    }
    fetchContact()
  }, [lang])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div ref={contactRef} className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.scillsTitle}>{t('contactUs')}</h2>

        <div className={styles.content}>
          <div className={styles.leftSide}>
            <div className={`${styles.details} ${styles.address}`}>
              <IoLocationOutline className={styles.icon} />
              <div className={styles.topic}>Հասցե</div>
              <div className={styles.textOne}>{contact?.city}</div>
              <div className={styles.textTwo}>{contact?.location}</div>
            </div>
            <div className={`${styles.details} ${styles.phone}`}>
              <FiPhone className={styles.icon} />
              <div className={styles.topic}>Հեռախոսահամար</div>
              <div className={styles.textOne}>{contact?.phoneNumber}</div>
            </div>
            <div className={`${styles.details} ${styles.email}`}>
              <BiTimeFive className={styles.icon} />
              <div className={styles.topic}>Աշխատանքային Օրեր</div>
              <div className={styles.textOne}>{contact?.daysInWeek}</div>
              <div className={styles.textTwo}>{contact?.hours}</div>
            </div>
          </div>
          <div className={styles.rightSide}>
            <iframe
              allowFullScreen
              src="https://yandex.com/map-widget/v1/?ll=44.502390%2C40.203659&mode=whatshere&whatshere%5Bpoint%5D=44.502390%2C40.203659&whatshere%5Bzoom%5D=17&z=14.45"
              width="600"
              height="450"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className={styles.map}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
