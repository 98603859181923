import React from 'react'
import styles from './Wildberries.module.css'
import { useTranslation } from 'react-i18next'

export default function Wildberries() {
  const { t, i18n } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h4 className={styles.contentTitle}>{t('wildberriesTitle')}</h4>
        <img
          src="/image/wildberries.png"
          alt="WILDBERRIES"
          className={styles.wildberriesLogoImg}
          onClick={() => {
            window.open(
              'https://www.wildberries.ru/brands/310710535-nelinka',
              '_blank'
            )
          }}
        />
      </div>
    </div>
  )
}
