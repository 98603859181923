import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './ProductCard.module.css'
import { TbCurrencyDram } from 'react-icons/tb'
import { useSelector } from 'react-redux'

export default function ProductCard({ product, isMain }) {
  const [webpSupported, setWebpSupported] = useState(false)
  const navigate = useNavigate()
  const lang = useSelector((state) => state.lang.lang)

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  function handleNavigateToAllProductPage(id) {
    navigate(`/allProducts/product/${id}`)
  }

  return (
    <div
      key={product?.id}
      className={styles.product}
      onClick={() => handleNavigateToAllProductPage(product?.id)}>
      <div className={styles.imgContainer}>
        <img
          src={
            webpSupported &&
            product[`imgs_${lang.toLowerCase()}`] &&
            Array.isArray(product[`imgs_${lang.toLowerCase()}`]) &&
            product[`imgs_${lang.toLowerCase()}`][0]?.webp
              ? product[`imgs_${lang.toLowerCase()}`][0]?.webp
              : ''
          }
          alt={product?.name}
          className={`${styles.productImg} ${
            isMain ? styles.productImgFullWidth : ''
          }`}
        />
        <div className={styles.prodTxtx}>
          <h4>Nelinka</h4>
        </div>
      </div>
      <div className={styles.content}>
        <h2 className={styles.productTitle}>
          {product[`name_${lang.toLowerCase()}`]}
        </h2>
        <p className={styles.productType}>{product?.categoryValue}</p>
        <p className={styles.productPrice}>
          <TbCurrencyDram className={styles.dramIcon} />
          {product[`price_${lang.toLowerCase()}`]}
        </p>
      </div>
    </div>
  )
}
