import styles from './AdminContact.module.css'
import { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { BsPersonVcard } from 'react-icons/bs'
import { IoChevronForward } from 'react-icons/io5'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import { ImLocation } from 'react-icons/im'
import { GiModernCity } from 'react-icons/gi'
import { toast, ToastContainer } from 'react-toastify'
import { BiCurrentLocation } from 'react-icons/bi'
import { Ri24HoursFill } from 'react-icons/ri'
import { IoCalendarSharp } from 'react-icons/io5'
import { BsTelephoneFill, BsFillDoorClosedFill } from 'react-icons/bs'
import { FaCalendarWeek } from 'react-icons/fa'
import { HiPhone } from 'react-icons/hi'
import { Modal as AntDModal } from 'antd'
import Loader from '../../components/Loader/Loader'

export default function AdminInfoAccordeon() {
  const [preLoader, setPreLoader] = useState(true)
  const [isActive, setIsActive] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [infoAccordeon, setInfoAccordeon] = useState([])
  const [lang, setLang] = useState('ENG')
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [infoId, setInfoId] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [editValues, setEditValues] = useState({
    location: '',
    index: '',
    city: '',
    hours: '',
    close: '',
    daysInWeek: '',
    phoneNumber: '',
  })
  const [values, setValues] = useState({
    location: '',
    index: '',
    city: '',
    hours: '',
    close: '',
    daysInWeek: '',
    phoneNumber: '',
  })

  async function handleClickOpenEdit(item) {
    setOpenEdit(true)
    setInfoId(item.id)
    setEditValues({
      location: item?.location,
      index: item?.index,
      city: item?.city,
      hours: item?.hours,
      close: item?.close,
      daysInWeek: item?.daysInWeek,
      phoneNumber: item?.phoneNumber,
    })
  }

  function handleCloseEdit() {
    setOpenEdit(false)
    setEditValues({
      location: '',
      index: '',
      city: '',
      hours: '',
      close: '',
      daysInWeek: '',
      phoneNumber: '',
    })
  }

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  useEffect(() => {
    if (lang === 'ENG') {
      setValues((prev) => ({ ...prev, no: 1 }))
    } else if (lang === 'RUS') {
      setValues((prev) => ({ ...prev, no: 2 }))
    } else if (lang === 'ARM') {
      setValues((prev) => ({ ...prev, no: 3 }))
    }
  }, [lang])

  const handleClickOpen = () => {
    setOpen(true)
  }

  function handleChangeValues(e) {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  function handleChangeValuesEdit(e) {
    setEditValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleClose = () => {
    setOpen(false)
  }

  function handleChange(e) {
    setLang(e.target.value)
  }

  const fetchInfos = async () => {
    setPreLoader(true)
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/contactNelinka`
      )
      const filteredDatas =
        res.data && res.data.filter((item) => item.lang === lang.toLowerCase())

      setInfoAccordeon(filteredDatas.slice(0, 3))
      setPreLoader(false)
    } catch (error) {
      toast.error(error.message)
      setPreLoader(false)
    }
  }

  useEffect(() => {
    fetchInfos()
  }, [values.no])

  async function sendingValues() {
    try {
      await axios
        .post(`${process.env.REACT_APP_SERVER_API}/addContactNelinka`, {
          ...values,
          lang: lang.toLowerCase(),
        })
        .then(() => {
          toast.success('Partner has been created successfully !')
          setValues({
            location: '',
            index: '',
            city: '',
            hours: '',
            close: '',
            daysInWeek: '',
            phoneNumber: '',
          })
          window.location.reload()
        })
      setOpen(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  async function handleDelete(id) {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_API}/deleteContactNelinka/${id}`
      )
      await fetchInfos()
    } catch (error) {
      toast.error(error.message)
    }
  }
  async function sendValuesEdit(id) {
    try {
      await axios
        .put(`${process.env.REACT_APP_SERVER_API}/editContactNelinka/${id}`, {
          ...editValues,
        })
        .then(() => {
          setLoadingEdit(false)
          setOpenEdit(false)
          setEditValues({
            location: '',
            index: '',
            city: '',
            hours: '',
            close: '',
            daysInWeek: '',
            phoneNumber: '',
          })
          fetchInfos()
          toast.success('Partner has been edit successfully !')
        })
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    }
  }

  return (
    <div className={styles.wrapper}>
      <h2 style={{ marginBottom: '100px' }}>Home page last section</h2>

      <div className={styles.actions}>
        <div className={styles.languageContainer}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Test</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lang}
                label="Age"
                onChange={handleChange}>
                <MenuItem value="ENG">ENG</MenuItem>
                <MenuItem value={'ARM'}>ARM</MenuItem>
                <MenuItem value="RUS">RUS</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
      <div className={styles.container}>
        {infoAccordeon[0] && (
          <div className={styles.content}>
            <div className={styles.btns}>
              <div
                onClick={() => {
                  setItemId(infoAccordeon[0]?.id)
                  showModal()
                }}
                className={`${styles.button} ${styles.button2}`}>
                Հեռացնել
              </div>
              <div
                onClick={() => handleClickOpenEdit(infoAccordeon[0])}
                className={`${styles.button} ${styles.button3}`}>
                Փոփողել
              </div>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <ImLocation className={styles.phoneIcon} />{' '}
                </span>
                Երկիր :
              </div>
              <h4 className={styles.title}>{infoAccordeon[0]?.location}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <BiCurrentLocation className={styles.phoneIcon} />
                </span>
                Ինդեքս :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[0]?.index}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Քաղաք :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[0]?.city}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <Ri24HoursFill className={styles.phoneIcon} />
                </span>
                Ժամեր :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[0]?.hours}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <BsFillDoorClosedFill className={styles.phoneIcon} />
                </span>
                Փակ է այս օրերին` :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[0]?.close}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <FaCalendarWeek className={styles.phoneIcon} />
                </span>
                Աշխատանքային օրեր :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[0]?.daysInWeek}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <HiPhone className={styles.phoneIcon} />
                </span>
                Հեռախոսահամար :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[0]?.phoneNumber}</h4>
            </div>
          </div>
        )}
        {infoAccordeon[1] && (
          <div className={styles.content}>
            <div className={styles.btns}>
              <div
                onClick={() => {
                  setItemId(infoAccordeon[1]?.id)
                  showModal()
                }}
                className={`${styles.button} ${styles.button2}`}>
                Հեռացնել
              </div>
              <div
                onClick={() => handleClickOpenEdit(infoAccordeon[1])}
                className={`${styles.button} ${styles.button3}`}>
                Փոփողել
              </div>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <ImLocation className={styles.phoneIcon} />{' '}
                </span>
                Երկիր :
              </div>
              <h4 className={styles.title}>{infoAccordeon[1]?.location}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Ինդեքս :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[1]?.index}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Քաղաք :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[1]?.city}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Աշխատանքային Ժամեր :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[1]?.hours}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Փակ է այս օրերին` :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[1]?.close}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <IoCalendarSharp className={styles.phoneIcon} />
                </span>
                Աշխատանքային օրեր :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[1]?.daysInWeek}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <HiPhone className={styles.phoneIcon} />
                </span>
                Հեռախոսահամար :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[1]?.phoneNumber}</h4>
            </div>
          </div>
        )}
        {infoAccordeon[2] && (
          <div className={styles.content}>
            <div className={styles.btns}>
              <div
                onClick={() => {
                  setItemId(infoAccordeon[2]?.id)
                  showModal()
                }}
                className={`${styles.button} ${styles.button2}`}>
                Հեռացնել
              </div>
              <div
                onClick={() => handleClickOpenEdit(infoAccordeon[2])}
                className={`${styles.button} ${styles.button3}`}>
                Փոփողել
              </div>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <ImLocation className={styles.phoneIcon} />{' '}
                </span>
                Երկիր :
              </div>
              <h4 className={styles.title}>{infoAccordeon[2]?.location}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Ինդեքս :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[2]?.index}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Քաղաք :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[2]?.city}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <Ri24HoursFill className={styles.phoneIcon} />
                </span>
                Աշխատանքային Ժամեր :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[2]?.hours}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Փակ է այս օրերին` :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[2]?.close}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Աշխատանքային օրեր :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[2]?.daysInWeek}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <HiPhone className={styles.phoneIcon} />
                </span>
                Հեռախոսահամար :
              </div>
              <h4 className={styles.desc}>{infoAccordeon[2]?.phoneNumber}</h4>
            </div>
          </div>
        )}
        {infoAccordeon.length < 1 && (
          <div onClick={handleClickOpen} className={styles.addBtnContainer}>
            <div className={`${styles.button} ${styles.button4}`}>
              Add Contact with {lang}
            </div>
          </div>
        )}

        <Dialog open={open} onClose={handleClose}>
          <Box sx={{ minWidth: '600px', height: '600px' }}>
            <DialogTitle>Add {lang} variant</DialogTitle>
            <div style={{ padding: '20px' }}>
              <TextField
                required
                autoFocus
                margin="dense"
                value={editValues.title}
                onChange={handleChangeValues}
                name="location"
                id="location"
                label="Location"
                type="text"
                fullWidth
                variant="standard"
                style={{ marginBottom: '20px' }}
              />
              <TextField
                required
                autoFocus
                value={editValues.description}
                onChange={handleChangeValues}
                name="index"
                margin="index"
                id="index"
                label="Location Index"
                type="email"
                fullWidth
                variant="standard"
                style={{ marginBottom: '20px' }}
              />
              <TextField
                required
                autoFocus
                value={editValues.description}
                onChange={handleChangeValues}
                name="city"
                margin="city"
                id="city"
                label="City"
                type="text"
                fullWidth
                variant="standard"
                style={{ marginBottom: '20px' }}
              />
              <TextField
                required
                autoFocus
                value={editValues.description}
                onChange={handleChangeValues}
                name="hours"
                margin="hours"
                id="hours"
                label="Hours"
                type="text"
                fullWidth
                variant="standard"
                style={{ marginBottom: '20px' }}
              />
              <TextField
                required
                autoFocus
                value={editValues.description}
                onChange={handleChangeValues}
                name="daysInWeek"
                margin="daysInWeek"
                id="daysInWeek"
                label="Days In Week"
                type="text"
                fullWidth
                variant="standard"
                style={{ marginBottom: '20px' }}
              />
              <TextField
                required
                autoFocus
                value={editValues.description}
                onChange={handleChangeValues}
                name="close"
                margin="close"
                id="close"
                label="Close"
                type="email"
                fullWidth
                variant="standard"
                style={{ marginBottom: '20px' }}
              />
              <TextField
                required
                autoFocus
                value={editValues.description}
                onChange={handleChangeValues}
                name="phoneNumber"
                margin="phoneNumber"
                id="phoneNumber"
                label="Phone Number"
                type="email"
                fullWidth
                variant="standard"
                style={{ marginBottom: '60px' }}
              />

              <Stack direction="row" spacing={2}>
                <LoadingButton onClick={handleClose} variant="outlixaned">
                  Cancel
                </LoadingButton>
                <LoadingButton
                  onClick={sendingValues}
                  endIcon={<SendIcon />}
                  loading={loading}
                  variant="contained">
                  <span>Send</span>
                </LoadingButton>
              </Stack>
            </div>
          </Box>
        </Dialog>
        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit {lang} variant</DialogTitle>
          <div style={{ padding: '16px' }}>
            <TextField
              required
              autoFocus
              value={editValues?.location}
              onChange={handleChangeValuesEdit}
              name="location"
              id="location"
              label="Location"
              type="text"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              required
              autoFocus
              value={editValues?.index}
              onChange={handleChangeValuesEdit}
              name="index"
              margin="index"
              id="index"
              label="Location Index"
              type="email"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              required
              autoFocus
              value={editValues?.city}
              onChange={handleChangeValuesEdit}
              name="city"
              margin="city"
              id="city"
              label="City"
              type="text"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              required
              autoFocus
              value={editValues?.hours}
              onChange={handleChangeValuesEdit}
              name="hours"
              margin="hours"
              id="hours"
              label="Hours"
              type="text"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              required
              autoFocus
              value={editValues?.daysInWeek}
              onChange={handleChangeValuesEdit}
              name="daysInWeek"
              margin="daysInWeek"
              id="daysInWeek"
              label="Days In Week"
              type="text"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              required
              autoFocus
              value={editValues?.close}
              onChange={handleChangeValuesEdit}
              name="close"
              margin="close"
              id="close"
              label="Close"
              type="email"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              required
              autoFocus
              value={editValues?.phoneNumber}
              onChange={handleChangeValuesEdit}
              name="phoneNumber"
              margin="phoneNumber"
              id="phoneNumber"
              label="Phone Number"
              type="email"
              fullWidth
              variant="standard"
              style={{ marginBottom: '60px' }}
            />

            <Stack direction="row" spacing={2}>
              <LoadingButton onClick={handleCloseEdit} variant="outlixaned">
                Cancel
              </LoadingButton>
              <LoadingButton
                onClick={() => sendValuesEdit(infoId)}
                endIcon={<SendIcon />}
                loading={loadingEdit}
                variant="contained">
                <span>Edit</span>
              </LoadingButton>
            </Stack>
          </div>
        </Dialog>
      </div>
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDelete(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      <ToastContainer />
      {preLoader && <Loader />}
    </div>
  )
}
