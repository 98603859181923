import React, { useEffect, useState } from 'react'
import styles from './AdminWebSiteStatus.module.css'
import { Switch } from 'antd'
import axios from 'axios'

export default function AdminWebSiteStatus() {
  const [status, setStatus] = useState('')

  useEffect(() => {
    const fetchWebsiteStatus = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/statusNelinka`
        )
        setStatus(data[0].status)
      } catch (error) {
        console.log(error)
      }
    }
    fetchWebsiteStatus()
  }, [])

  useEffect(() => {
    const editStatus = async () => {
      try {
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_API}/edit/websiteStatusNelinka`,
            {
              status: status,
            }
          )
          .then((res) => {
            console.log('EDIT STATUS RES')
            console.log(res)
          })
          .catch((err) => {
            console.log('EDIT STATUS errr')
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    }
    editStatus()
  }, [status])

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>Կայքի կարգավիճակի ռեժիմ</h2>

        <div className={styles.switchsContainer}>
          <div className={styles.switch}>
            <label className={styles.label}>Անցանց ռեժիմ (Offline):</label>
            <Switch
              style={{
                backgroundColor:
                  status === 'offline' ? 'rgb(207, 75, 149)' : 'gray',
              }}
              checked={status === 'offline' && true}
              onClick={() => setStatus('offline')}
            />
          </div>
          <div className={styles.switch}>
            <label className={styles.label}>Առցանց ռեժիմ (Online):</label>
            <Switch
              style={{
                backgroundColor:
                  status === 'online' ? 'rgb(207, 75, 149)' : 'gray',
              }}
              checked={status === 'online' && true}
              onClick={() => setStatus('online')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
