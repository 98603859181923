import styles from './AddProductPage.module.css'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'
import { MdAddCircle } from 'react-icons/md'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { TbFlag3Filled } from 'react-icons/tb'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import { IoIosPricetag } from 'react-icons/io'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import Typography from '@mui/material/Typography'
import SendIcon from '@mui/icons-material/Send'
import Modal from '@mui/material/Modal'
import { Editor } from '@tinymce/tinymce-react'
import DescriptionIcon from '@mui/icons-material/Description'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { v4 as uuidv4 } from 'uuid'
import { AiFillPicture } from 'react-icons/ai'
import Switch from '@mui/material/Switch'
import { GiSightDisabled } from 'react-icons/gi'
import RefreshIcon from '@mui/icons-material/Refresh'
import dayjs from 'dayjs'
import { TbRefreshDot } from 'react-icons/tb'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import storage from '../../firebase'
import { ToastContainer, toast } from 'react-toastify'
import imageCompression from 'browser-image-compression'
import { Select as AntSelect, Tag } from 'antd'
import Loader from '../../components/Loader/Loader'
import Button from '@mui/material/Button'
import { BarLoader } from 'react-spinners'
import { Button as AntdButton, Dropdown } from 'antd'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: -10,
  p: 4,
}

const tagRender = (props) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color: 'white',
        background: 'rgb(207, 75, 149)',
        display: 'flex',
        alignItems: 'center',
      }}>
      {label}
    </Tag>
  )
}

const tagRenderMaterial = (props) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color: 'white',
        background: 'rgb(207, 75, 149)',
        display: 'flex',
        alignItems: 'center',
      }}>
      {label}
    </Tag>
  )
}
const tagRenderCategory = (props) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color: 'white',
        background: 'rgb(207, 75, 149)',
        display: 'flex',
        alignItems: 'center',
      }}>
      {label}
    </Tag>
  )
}

export default function AddProductPage() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState(null)
  const [content, setContent] = useState('')
  const [open, setOpen] = useState('')
  const [sendCategoryLoading, setSendCategoryLoading] = useState(false)
  const [sendMaterialLoading, setSendMaterialLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [materials, setMaterials] = useState([])
  const [webpSupported, setWebpSupported] = useState(false)
  const [colors, setColors] = useState([])
  const [sizes, setSizes] = useState([])
  const [sleeves, setSleeves] = useState([])
  const [decorativeElements, setDecorativeElements] = useState([])
  const [genders, setGenders] = useState([])
  const [seasons, setSeasons] = useState([])
  const [imgLoader, setImgLoader] = useState(false)
  const [addCategory, setAddCategory] = useState({
    category: '',
    disabled: 0,
    lang: '',
  })
  const [addMaterial, setAddMaterial] = useState({
    material: '',
    disabled: 0,
    lang: '',
  })
  const [sendingValues, setSendingValues] = useState({
    name: '',
    status: 0,
    price: 0,
    category: [],
    material: [],
    colors: [],
    imgs: [],
    description: '',
    height: 0,
    width: 0,
    depth: 0,
    disabled: 0,
    oldPrice: 0,
    date: dayjs().format('DD.MM.YYYY'),
  })
  const [test, setTest] = useState([])
  const [materialTest, setMaterialTest] = useState([])
  const [sizeTest, setSizeTest] = useState([])
  const [sleeveTest, setSleeveTest] = useState([])
  const [decorativeElementTest, setDecorativeElementTest] = useState([])
  const [genderTest, setGenderTest] = useState([])
  const [seasonTest, setSeasonTest] = useState([])
  const [colorTest, setColorTest] = useState([])
  const fileInputRef = useRef(null)

  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  const handleDeleteImage = (img) => {
    const imageRefJpeg = ref(storage, img.jpeg)
    const imageRefWebp = ref(storage, img.webp)

    deleteObject(imageRefJpeg).then(() =>
      console.log('Jpeg has been delete sucess')
    )
    deleteObject(imageRefWebp).then(() =>
      console.log('Webp has been elete success')
    )

    setSendingValues((prev) => ({
      ...prev,
      imgs: [
        ...sendingValues.imgs.filter((filteredImg) => filteredImg !== img),
      ],
    }))
  }

  const handleEditorChange = (content, editor) => {
    setContent(content)
  }

  const handleSendValues = async (e) => {
    setLoading(true)
    let categoriesArray = []
    let materialsArray = []
    let colorsArray = []
    let sizesArray = []
    let decorativeElementsArray = []
    let sleevesArray = []
    let seasonsArray = []
    let gendersArray = []
    const url = `${process.env.REACT_APP_SERVER_API}/addProductFurnitureNelinka/arm`
    if (Array.isArray(test) && test.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/categoriesNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => test.includes(item.category))

      categoriesArray = filteredDatas
    }

    if (Array.isArray(materialTest) && materialTest.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/materialsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => materialTest.includes(item.material))

      materialsArray = filteredDatas
    }
    if (Array.isArray(colorTest) && colorTest.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/colorsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => colorTest.includes(item.color))
          .map((item) => {
            return { color: item.color, label: item.color, value: item.color }
          })

      colorsArray = filteredDatas
    }

    if (Array.isArray(sizeTest) && sizeTest.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/sizesNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sizeTest.includes(item.size))

      sizesArray = filteredDatas
    }
    if (
      Array.isArray(decorativeElementTest) &&
      decorativeElementTest.length > 0
    ) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/decorativeElementsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) =>
            decorativeElementTest.includes(item.decorativeElement)
          )

      decorativeElementsArray = filteredDatas
    }
    if (Array.isArray(seasonTest) && seasonTest.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/seasonsNelinka`
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => seasonTest.includes(item.season))

      seasonsArray = filteredDatas
    }
    if (Array.isArray(sleeveTest) && sleeveTest.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/sleevesNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sleeveTest.includes(item.sleeveType))

      sleevesArray = filteredDatas
    }
    if (Array.isArray(genderTest) && genderTest.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/gendersNelinka`
      )

      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => genderTest.includes(item.gender))

      gendersArray = filteredDatas
    }

    try {
      await axios
        .post(url, {
          ...sendingValues,
          material: JSON.stringify(materialsArray),
          category: JSON.stringify(categoriesArray),
          imgs: JSON.stringify(sendingValues.imgs),
          colors: JSON.stringify(colorsArray),
          size: JSON.stringify(sizesArray),
          sleeve: JSON.stringify(sleevesArray),
          season: JSON.stringify(seasonsArray),
          decorativeElement: JSON.stringify(decorativeElementsArray),
          gender: JSON.stringify(gendersArray),
        })
        .then((res) => {
          setTimeout(() => {
            setLoading(false)
            toast.success('Product has been created successfully !')
            navigate('/admin/admindashboard/products')
          }, 3000)
        })
        .catch((err) => {
          console.log('error')
          console.log(err)
        })
    } catch (error) {
      toast.error('error')
      toast.error(error)
    }
  }

  useEffect(() => {
    const container = document.createElement('p')

    container.innerHTML = content

    const descriptionElement = container.querySelector('p')
    const description = descriptionElement && descriptionElement.outerHTML

    setSendingValues((prev) => ({ ...prev, description: description }))
  }, [content])

  const onInputChange = (e) => {
    setFiles(e.target.files)
  }

  async function uploadImgProduct() {
    let newFileNames = []
    let downloadURLs = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const newFileName = uuidv4() + '.' + file.name.split('.').pop()
      newFileNames.push(newFileName)
      setImgLoader(true)

      const jpegImageRef = ref(
        storage,
        `images/testProducts/${newFileName}.jpeg`
      )
      const webpImageRef = ref(
        storage,
        `images/testProducts/${newFileName}.webp`
      )

      try {
        const compressedImage = await imageCompression(file, {
          maxSize: 500 * 1024,
          maxWidthOrHeight: 800,
        })

        const jpegCompressedImage = await imageCompression(compressedImage, {
          fileType: 'jpeg',
        })

        const webpCompressedImage = await imageCompression(compressedImage, {
          fileType: 'webp',
        })

        await uploadBytes(jpegImageRef, jpegCompressedImage)
        await uploadBytes(webpImageRef, webpCompressedImage).then(() => {
          toast.success('Image uploaded success !')
        })

        const jpegDownloadURL = await getDownloadURL(jpegImageRef)
        const webpDownloadURL = await getDownloadURL(webpImageRef)

        const imgObj = { jpeg: jpegDownloadURL, webp: webpDownloadURL }
        downloadURLs.push(imgObj)
      } catch (error) {
        toast.error(error.message)
      }
    }

    setSendingValues((prev) => ({
      ...prev,
      imgs: [...prev.imgs, ...downloadURLs],
    }))
    setImgLoader(false)
  }

  useEffect(() => {
    if (files) {
      uploadImgProduct()
    }
  }, [files])

  const handleInputChange = (e) => {
    setAddCategory((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/categoriesNelinka`
        )
        const filteredDatas =
          res.data.length &&
          res.data
            .filter(
              (item) => !item.disabled && item.lang.toLowerCase() === 'arm'
            )
            .map((item) => {
              return { value: item.category }
            })
        setOptions(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchCategories()
  }, [])

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/materialsNelinka`
        )
        const filteredDatas =
          res.data.length &&
          res.data
            .filter(
              (item) => !item.disabled && item.lang.toLowerCase() === 'arm'
            )
            .map((item) => {
              return {
                value: item.material,
              }
            })

        setMaterials(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchMaterials()
  }, [])

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/colorsNelinka`
        )

        const filteredDatas =
          res.data.length &&
          res.data
            .filter(
              (item) => !item.disabled && item.lang.toLowerCase() === 'arm'
            )
            .map((item) => {
              return {
                value: item.color,
              }
            })

        setColors(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchColors()
  }, [])

  useEffect(() => {
    const fetchSizes = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/sizesNelinka`
        )
        const filteredDatas =
          res.data.length &&
          res.data
            .filter((item) => !item.disabled)
            .map((item) => {
              return {
                value: item.size,
              }
            })

        setSizes(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchSizes()
  }, [])

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/seasonsNelinka`
        )
        console.log('SEASONS RESPONSE')
        console.log(res)
        const filteredDatas =
          res.data.length &&
          res.data
            .filter(
              (item) => !item.disabled && item.lang.toLowerCase() === 'arm'
            )
            .map((item) => {
              return {
                value: item.season,
              }
            })

        setSeasons(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchSeasons()
  }, [])

  useEffect(() => {
    const fetchDecorativeElements = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/decorativeElementsNelinka`
        )
        console.log('DECORATIVE ELEMENTS RESPONSE')
        console.log(res)
        const filteredDatas =
          res.data.length &&
          res.data
            .filter(
              (item) => !item.disabled && item.lang.toLowerCase() === 'arm'
            )
            .map((item) => {
              return {
                value: item.decorativeElement,
              }
            })

        setDecorativeElements(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchDecorativeElements()
  }, [])
  useEffect(() => {
    const fetchGenders = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/gendersNelinka`
        )
        console.log('GENDERS RESPONSE')
        console.log(res)
        const filteredDatas =
          res.data.length &&
          res.data
            .filter(
              (item) => !item.disabled && item.lang.toLowerCase() === 'arm'
            )
            .map((item) => {
              return {
                value: item.gender,
              }
            })

        setGenders(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchGenders()
  }, [])
  useEffect(() => {
    const fetchSleeves = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/sleevesNelinka`
        )

        const filteredDatas =
          res.data.length &&
          res.data
            .filter(
              (item) => !item.disabled && item.lang.toLowerCase() === 'arm'
            )
            .map((item) => {
              return {
                value: item.sleeveType,
              }
            })

        setSleeves(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchSleeves()
  }, [])

  const defaultProps = {
    options: options,
    getOptionLabel: (option) => option.category,
    key: uuidv4(),
  }

  const handleOpenCategory = () => setOpen('addCategory')
  const handleCloseCategory = () => {
    setOpen('')
    setAddCategory({ category: '', disabled: 0 })
  }
  const handleOpenMaterial = () => setOpen('addMaterial')
  const handleCloseMaterial = () => {
    setOpen('')
    setAddMaterial({ material: '', disabled: 0 })
  }

  async function handleSend(e) {
    e.preventDefault()
    setSendCategoryLoading(true)

    try {
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_API}/addcategoryNelinka`,
          addCategory
        )
        .then(() => {
          setOpen('')
          setSendCategoryLoading(false)
          setAddCategory({ category: '', disabled: 0 })
          toast.success('Category has been created successfully !')
        })
      const updatedCategories = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/categoriesNelinka`
      )
      const filteredDatas =
        updatedCategories.data.length &&
        updatedCategories.data
          .filter((item) => !item.disabled)
          .map((item) => {
            return { value: item.category }
          })
      setOptions(filteredDatas)
    } catch (error) {
      toast.error(error.message)
    }
  }
  const handleSwitchChange = (e) => {
    setSendingValues((prev) => ({
      ...prev,
      disabled: prev.disabled ? 0 : 1,
    }))
  }

  async function handleSendMaterial(e) {
    e.preventDefault()
    setSendMaterialLoading(true)
    try {
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_API}/addmaterialNelinka`,
          addMaterial
        )
        .then(() => {
          setOpen('')
          setSendMaterialLoading(false)
          setAddMaterial({ material: '', disabled: 0 })
          toast.success('Material has been created successfully !')
        })
      const updatedMaterials = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/materialsNelinka`
      )
      const filteredDatas =
        updatedMaterials.data.length &&
        updatedMaterials.data
          .filter((item) => !item.disabled)
          .map((item) => {
            return {
              value: item.material,
            }
          })
      setMaterials(filteredDatas)
    } catch (error) {
      toast.error(error.message)
    }
  }

  function handleMaterialChange(e) {
    setAddMaterial((prev) => ({ ...prev, material: e.target.value }))
  }
  function handleMaterialChangeLang(e) {
    setAddMaterial((prev) => ({ ...prev, lang: e.target.value }))
  }

  const handleFilePicker = (callback, value, meta) => {
    if (meta.filetype === 'image') {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/jpeg')
      input.onchange = function () {
        const file = this.files[0]
        callback(`/image/main/${file.name}`)
      }
      input.click()
    }
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  const handleChangeSize = (selectedValues) => {
    setSizeTest([...selectedValues])
  }
  const handleChangeSeason = (selectedValues) => {
    setSeasonTest([...selectedValues])
  }
  const handleChangeDecorativeElement = (selectedValues) => {
    setDecorativeElementTest([...selectedValues])
  }
  const handleChangeSleeve = (selectedValues) => {
    setSleeveTest([...selectedValues])
  }
  const handleChangeGender = (selectedValues) => {
    setGenderTest([...selectedValues])
  }
  const handleChangeCategory = (selectedValues) => {
    setTest([...selectedValues])
  }
  const handleChangeMaterial = (selectedValues) => {
    setMaterialTest([...selectedValues])
  }

  const handleChangeColors = (selectedValues) => {
    setColorTest([...selectedValues])
  }

  return (
    <section className={styles.section}>
      <div className={styles.containerHeader}>
        <header className={styles.header}>
          <div className={styles.leftHeader}>
            <h1>Ապրանքի ստեղծում</h1>
          </div>
          <div className={styles.rightHeader}>
            <div className={styles.backContainer}>
              <Breadcrumbs aria-label="breadcrumb">
                <RouterLink to="/admin/admindashboard/products">
                  <div className={styles.backContainer}>
                    <HiOutlineArrowNarrowLeft className={styles.backIcon} />
                    հետ Գնալ
                  </div>
                </RouterLink>
                <button onClick={handleSendValues} className={styles.btn}>
                  <MdAddCircle
                    onClick={handleSendValues}
                    className={styles.iconBtn}
                  />
                  Ստեղծել
                </button>
              </Breadcrumbs>
            </div>
          </div>
        </header>
      </div>
      <div className={styles.container}>
        <div className={styles.disabledContainer}>
          {/* <Dropdown
            menu={{
              items: languageItems,
            }}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}>
            <AntdButton>Currency</AntdButton>
          </Dropdown> */}
          <div className={styles.disabled}>
            <label className={styles.disabledLabel}>
              <GiSightDisabled className={styles.disabledIcon} />
              Disabled:
            </label>
            <Switch
              {...label}
              checked={sendingValues.disabled === 1}
              onChange={handleSwitchChange}
            />
          </div>
        </div>
        <div className={styles.formNameContainer}>
          <label className={styles.labelName}>
            <TbFlag3Filled className={styles.productNameIcon} />
            Ապրանքի Անուն:
          </label>
          <Box
            sx={{
              width: 300,
              maxWidth: '100%',
            }}>
            <TextField
              label="Անուն"
              value={sendingValues.name}
              onChange={(e) =>
                setSendingValues((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              id="outlined-size-small"
              size="small"
              fullWidth
            />
          </Box>
        </div>
        <div className={styles.statusContainer}>
          <label className={styles.labelStatus}>
            <MilitaryTechIcon />
            Կարգավիճակ:
          </label>
          <div className={styles.statusWrapperContainer}>
            <div
              className={`${styles.status_es} ${
                sendingValues.status === 1 && styles.statusRef
              }`}>
              <Box
                sx={{
                  width: 'auto',
                  minWidth: 100,
                  paddingRight: 1,
                  paddingLeft: 1,
                }}>
                <Grid
                  container
                  justifyContent="center"
                  className={styles.statusIconContainer}>
                  <Tooltip title="Hit Status & Home Status" placement="top">
                    <WhatshotIcon
                      onClick={() =>
                        setSendingValues((prev) => ({ ...prev, status: 1 }))
                      }
                      className={styles.statusIcon}
                    />
                  </Tooltip>
                  <span
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 1 }))
                    }>
                    հիթ
                  </span>
                </Grid>
              </Box>
            </div>
            <div
              className={`${styles.status_es} ${
                sendingValues.status === 2 && styles.statusRef
              }`}>
              <Box
                sx={{
                  width: 'auto',
                  minWidth: 100,
                  paddingRight: 1,
                  paddingLeft: 1,
                }}>
                <Grid
                  container
                  justifyContent="center"
                  className={styles.statusIconContainer}>
                  <Tooltip title="Sale Items Status" placement="top">
                    <NewspaperIcon
                      onClick={() =>
                        setSendingValues((prev) => ({ ...prev, status: 2 }))
                      }
                      className={styles.statusIcon}
                    />
                  </Tooltip>
                  <span
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 2 }))
                    }>
                    Նորություն
                  </span>
                </Grid>
              </Box>
            </div>
          </div>
          <div
            className={`${styles.status_es} ${
              sendingValues.status === 0 && styles.statusRef
            }`}>
            <Box
              sx={{
                width: 'auto',
                minWidth: 100,
                paddingRight: 1,
                paddingLeft: 1,
              }}>
              <Grid
                container
                justifyContent="center"
                className={`${styles.statusIconContainer} ${styles.refreshContainer}`}>
                <Tooltip title="Reset" placement="top">
                  <RefreshIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 0 }))
                    }
                    className={`${styles.statusIcon} ${styles.restart}`}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 0 }))
                  }>
                  Վերականգնել
                </span>
              </Grid>
            </Box>
          </div>
        </div>

        <div className={styles.priceContainer}>
          <label style={{ marginRight: '20px' }} className={styles.labelPrice}>
            <IoIosPricetag className={styles.priceIcon} />
            Գին:
          </label>
          <TextField
            id="standard-textarea"
            label="Price"
            value={sendingValues.price}
            onChange={(e) =>
              setSendingValues((prev) => ({ ...prev, price: e.target.value }))
            }
            placeholder="Enter product price"
            multiline
            variant="standard"
          />{' '}
          <span style={{ marginTop: '13px', marginLeft: '20px' }}>AMD</span>
        </div>
        <div className={styles.priceContainer}>
          <label className={styles.labelOldPrice}>
            <TbRefreshDot className={styles.oldPrice} />
            Հին Գին. Պառտադիր չէ
          </label>
          <TextField
            id="standard-textarea"
            label="Old Price"
            value={sendingValues.oldPrice}
            onChange={(e) =>
              setSendingValues((prev) => ({
                ...prev,
                oldPrice: e.target.value,
              }))
            }
            placeholder="Enter old price"
            multiline
            variant="standard"
          />
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Կատեգորիա:</label>
          <AntSelect
            mode="multiple"
            showArrow
            tagRender={tagRenderCategory}
            onChange={handleChangeCategory}
            style={{
              width: '100%',
            }}
            options={options}
          />

          {/* <div className={styles.addNewCategory}>
            <button
              onClick={handleOpenCategory}
              className={`${styles.btn} ${styles.addCategoryButton}`}>
              Ստեղծել նոր կատեգորիա
            </button>
          </div> */}
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Մատերիալ:</label>
          <AntSelect
            mode="multiple"
            showArrow
            tagRender={tagRenderMaterial}
            onChange={handleChangeMaterial}
            style={{
              width: '100%',
            }}
            options={materials}
          />

          {/* <div className={styles.addNewCategory}>
            <button
              className={`${styles.btn} ${styles.addCategoryButton}`}
              onClick={handleOpenMaterial}>
              Ստեղծել նոր մատերիալ
            </button>
          </div> */}
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Չափսեր:</label>
          <AntSelect
            mode="multiple"
            showArrow
            tagRender={tagRenderMaterial}
            onChange={handleChangeSize}
            style={{
              width: '100%',
            }}
            options={sizes}
          />
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Սեզոններ:</label>
          <AntSelect
            mode="multiple"
            showArrow
            tagRender={tagRenderMaterial}
            onChange={handleChangeSeason}
            style={{
              width: '100%',
            }}
            options={seasons}
          />
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Դեկորատիվ Էլեմենտներ:</label>
          <AntSelect
            mode="multiple"
            showArrow
            tagRender={tagRenderMaterial}
            onChange={handleChangeDecorativeElement}
            style={{
              width: '100%',
            }}
            options={decorativeElements}
          />
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Սեռեր:</label>
          <AntSelect
            mode="multiple"
            showArrow
            tagRender={tagRenderMaterial}
            onChange={handleChangeGender}
            style={{
              width: '100%',
            }}
            options={genders}
          />
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Թևի Տեսակ:</label>
          <AntSelect
            mode="multiple"
            showArrow
            tagRender={tagRenderMaterial}
            onChange={handleChangeSleeve}
            style={{
              width: '100%',
            }}
            options={sleeves}
          />
        </div>

        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Գույներ:</label>
          <AntSelect
            mode="multiple"
            showArrow
            tagRender={tagRender}
            onChange={handleChangeColors}
            style={{
              width: '100%',
            }}
            options={colors}
          />
        </div>
        <div className={styles.editorContainer}>
          <label className={styles.descriptionLabel}>
            <DescriptionIcon />
            Նկարագրությունը:
          </label>
          <Editor
            className={styles.editor}
            apiKey="03r0cavlstl2k64nsmewzfymzwdhtinowah10xlnklhwn7tv"
            initialValue="<p>Գրեք ձեր ապրանքի նկարագրությունը...</p>"
            init={{
              height: 500,
              plugins: 'link image code',
              toolbar:
                'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | code',
              automatic_uploads: true,
              file_picker_types: 'image',
              file_picker_callback: handleFilePicker,
            }}
            onEditorChange={handleEditorChange}
          />
        </div>
        <form>
          <div className={styles.imgUploaderContainer}>
            <div className={styles.lableImgContainer}>
              <label className={styles.imgLabel}>
                <AiFillPicture className={styles.imgIcon} />
                Նկարներ:
              </label>
            </div>
            <div className={styles.imgWrapper}>
              <input
                onChange={onInputChange}
                type="file"
                id="file"
                accept="image/*"
                hidden
                multiple
                ref={fileInputRef}
              />
              <div className={styles.imgArea} data-img="">
                <CloudUploadIcon className={styles.uploadImgIcon} />
                {/* <Stack spacing={2} direction="row">
                  <Button onClick={handleSubmit} variant="outlined">
                    Հաստատել նկարները
                  </Button>
                </Stack> */}
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  // document.querySelector('#file').click()
                  fileInputRef?.current.click()
                }}
                className={styles.selectImage}>
                Ընտրել նկարներ
              </button>
            </div>
          </div>
          {imgLoader && <BarLoader width="100%" color="rgb(207, 75, 149)" />}
        </form>
        {sendingValues.imgs.length ? (
          <div className={styles.imgContainer}>
            {sendingValues.imgs.map((img) => (
              <div key={uuidv4()} className={styles.imgWrapper}>
                <img
                  className={styles.img}
                  src={webpSupported ? img?.webp : img?.jpeg}
                  alt="Product"
                />
                <div
                  className={styles.deleteBtn}
                  onClick={() => handleDeleteImage(img)}></div>
              </div>
            ))}
          </div>
        ) : null}

        <Stack spacing={2} direction="row">
          <Modal
            className={styles.modalZIndex}
            open={open === 'addCategory'}
            onClose={handleCloseCategory}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={styles.titleModal}>
                Ստեղծել նոր կատեգորիա
              </Typography>
              <Box
                className={styles.addInpCateogoryContainer}
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="standard-basic"
                  label="Կատեգորիաի անունը"
                  value={addCategory.category}
                  variant="standard"
                  color="primary"
                  name="category"
                  onChange={handleInputChange}
                />
              </Box>
              <Box
                className={styles.addInpCateogoryContainer}
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="standard-basic"
                  label="Լեզուն"
                  value={addCategory.lang}
                  variant="standard"
                  color="primary"
                  name="lang"
                  onChange={handleInputChange}
                />
              </Box>
              <Box className={styles.sendButtonContainer}>
                <Box sx={{ '& > button': { m: 1 } }}>
                  <LoadingButton
                    onClick={handleSend}
                    endIcon={<SendIcon />}
                    loading={sendCategoryLoading}
                    loadingPosition="end"
                    variant="contained">
                    <span>Ուղարկել</span>
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Stack>
        <Stack spacing={2} direction="row">
          <Modal
            className={styles.modalZIndex}
            open={open === 'addMaterial'}
            onClose={handleCloseMaterial}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={styles.titleModal}>
                Ստեղծել նոր Մատերիալ
              </Typography>
              <Box
                className={styles.addInpCateogoryContainer}
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="standard-basic"
                  label="Մատերիալի անունը"
                  value={addMaterial.material}
                  variant="standard"
                  color="primary"
                  name="category"
                  onChange={handleMaterialChange}
                />
              </Box>
              <Box
                className={styles.addInpMaterialContainer}
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="standard-basic"
                  label="Լեզու"
                  value={addMaterial.lang}
                  variant="standard"
                  color="primary"
                  name="lang"
                  onChange={handleMaterialChangeLang}
                />
              </Box>
              <Box className={styles.sendButtonContainer}>
                <Box sx={{ '& > button': { m: 1 } }}>
                  <LoadingButton
                    onClick={handleSendMaterial}
                    endIcon={<SendIcon />}
                    loading={sendCategoryLoading}
                    loadingPosition="end"
                    variant="contained">
                    <span>Ուղարկել</span>
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Stack>
      </div>
      <ToastContainer />
      {loading && <Loader />}
    </section>
  )
}
