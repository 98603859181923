import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MenuIcon from '@mui/icons-material/Menu'
import styles from './ProductsHeader.module.css'
import DropDownLanguage from '../DropDownLanguage/DropDownLanguage'
import { useSelector } from 'react-redux'
import { Drawer, useMediaQuery } from '@mui/material'
import { Box } from '@mui/material'
import { GrClose } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const langContainer = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
}

const itemsAnimation = {
  hidden: {
    y: -200,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
}

const ProductsHeader = () => {
  const navigate = useNavigate()
  const { aboutUs, products, projects, contactUs } = useSelector(
    (state) => state.sections
  )
  const { t } = useTranslation()
  const matches = useMediaQuery('(min-width:600px)')
  const [open, setOpen] = React.useState(false)
  const lang = useSelector((state) => state.lang.lang)
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const offset = -50

  const toggleDrawer = () => {
    setOpen(!open)
  }

  function handleNavigateToHome() {
    navigate('/')
    window.location.reload()
  }

  useEffect(() => {
    let language

    if (lang === 'ARM') {
      language = '/'
    } else if (lang === 'RUS') {
      language = '/ru'
    } else {
      language = '/en'
    }

    const metaTag = document.querySelector('meta[property="og:url"]')
    metaTag.setAttribute('content', language)

    return () => {
      metaTag.setAttribute('content', language)
    }
  }, [lang])

  return (
    <div className={styles.container}>
      <header initial="hidden" whileInView="visible" className={styles.header}>
        <div className={styles.imgContainer}>
          <img
            onClick={handleNavigateToHome}
            src="/image/nelinkaIcon.svg"
            alt="LOGO"
            className={styles.img}
          />
        </div>

        <motion.div
          variants={langContainer}
          custom={2}
          className={styles.langContainer}>
          <DropDownLanguage />
        </motion.div>
      </header>
    </div>
  )
}
export default ProductsHeader
