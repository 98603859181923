import { useRef, useState, useEffect } from 'react'
import styles from './Products.module.css'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setProducts } from '../../store/sections/sectionsAction'
import { useTranslation } from 'react-i18next'
import { TbCurrencyDram } from 'react-icons/tb'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import ProductCard from '../ProductCard/ProductCard'

export default function Products() {
  const [webpSupported, setWebpSupported] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [hiteProducts, setHiteProducts] = useState([])
  const productsRef = useRef(null)
  const lang = useSelector((state) => state.lang.lang)
  const [activeSize, setActiveSize] = useState({})
  const { t, i18n } = useTranslation()

  const responsive = {
    superLargeDesktop: {
      breakpoint: {
        max: 4000,
        min: 3000,
      },
      items: 5,
    },
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 4,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
  }

  useEffect(() => {
    if (productsRef?.current) {
      console.log('PRODUCTS REF')
      console.log(productsRef)
      dispatch(setProducts(productsRef))
    }
  }, [dispatch])

  function handleNavigateToAllProductPage(id) {
    navigate(`/allProducts/product/${id}`)
  }
  const fetchHiteProducts = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/productsNelinka`
      )
      const lowerCaseLanguage = lang.toLowerCase()

      const productsWithParsedJSON = res.data
        .map((item) => {
          console.log('ITEM')
          console.log(item)

          const imgs = item[`imgs_${lowerCaseLanguage}`]
            ? JSON.parse(item[`imgs_${lowerCaseLanguage}`])
            : []
          const colors = item[`colors_${lowerCaseLanguage}`]
            ? JSON.parse(item[`colors_${lowerCaseLanguage}`])
            : []
          const category = item[`category_${lowerCaseLanguage}`]
            ? JSON.parse(item[`category_${lowerCaseLanguage}`])
            : []

          const categoryItem = category.filter(
            (elem) => elem.lang === lang.toLowerCase()
          )

          const categoryValue =
            categoryItem.length > 0 ? categoryItem[0].category : null

          return {
            ...item,
            allCategories: category,
            [`imgs_${lowerCaseLanguage}`]: imgs,
            [`colors_${lowerCaseLanguage}`]: colors,
            [`category_${lowerCaseLanguage}`]: category,
            categoryValue,
          }
        })
        .filter((product) => product.status === 1 && !product.disabled)

      setHiteProducts(productsWithParsedJSON.slice(0, 6))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchHiteProducts()
  }, [])

  useEffect(() => {
    fetchHiteProducts()
  }, [lang])

  // useEffect(() => {
  //   setHiteProducts((prevProducts) =>
  //     prevProducts.map((product) => {
  //       const updatedCategories = product.allCategories
  //         .map((category) => {
  //           if (category.lang === lang.toLowerCase()) {
  //             return category
  //           }
  //           return null
  //         })
  //         .filter((category) => category !== null)

  //       return {
  //         ...product,
  //         category:
  //           updatedCategories.length > 0 ? updatedCategories[0].category : null,
  //       }
  //     })
  //   )
  // }, [lang])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const productsValue = productsRef?.current?.offsetTop

      localStorage.setItem('products', productsValue)
    })
  }, [productsRef?.current?.offsetTop])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <section ref={productsRef} className={styles.skill} id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={`${styles.skillBx} wow zoomIn`}>
              <h2 className={styles.scillsTitle}>{t('products')}</h2>
              <Carousel
                responsive={responsive}
                infinite={true}
                className={`owl-carousel owl-theme ${styles.skillSlider}`}>
                {hiteProducts &&
                  hiteProducts.map((product, index) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      isMain={true}
                    />
                  ))}
              </Carousel>
              <div className={styles.buttonContainer}>
                <Link to="/allProducts" className={styles.btnLink}>
                  <button className={styles.button}>{t('seeAll')}</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
