import {
    initializeApp
} from "firebase/app";
import {
    getStorage
} from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyD_G7VX4rKxFyZw8g-NaWmWp2FijciVp1E",
    authDomain: "nelinka-storage.firebaseapp.com",
    projectId: "nelinka-storage",
    storageBucket: "nelinka-storage.appspot.com",
    messagingSenderId: "227196432500",
    appId: "1:227196432500:web:b6e34bf84f6efd0b2ce121"
};


const app = initializeApp(firebaseConfig);
const storage = getStorage(app)

export default storage