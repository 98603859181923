import styles from './WeAre.module.css'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useRef, useEffect, useState } from 'react'
import Description from '../../pages/ProductPage/Description/Description'
import { setAboutUs } from '../../store/sections/sectionsAction'
import { useTranslation } from 'react-i18next'

export default function WeAre() {
  const [webpSupported, setWebpSupported] = useState(false)
  const selectRef = useRef(null)
  const lang = useSelector((state) => state.lang.lang)
  const dispatch = useDispatch()
  const [about, setAbout] = useState([])
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const isSmallScreen = screenWidth < 1175

  console.log('about about')
  console.log(about)

  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const fetchAboutUs = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/aboutNelinka`
      )

      const filteredData =
        res.data &&
        res?.data
          .filter((item) => item.lang === lang.toLowerCase())
          .map((item) => {
            return {
              id: item.id,
              lang: item.lang,
              img: JSON.parse(item.img),
              description: item.description,
            }
          })
      filteredData && setAbout(filteredData)
    } catch (error) {
      console.log(error)
    }
  }

  const { t, i18n } = useTranslation()

  useEffect(() => {
    fetchAboutUs()
  }, [lang])

  useEffect(() => {
    setTimeout(() => {
      const aboutValue = selectRef?.current?.offsetTop

      localStorage.setItem('aboutUs', aboutValue)
    }, 1000)
  }, [selectRef?.current?.offsetTop])

  useEffect(() => {
    if (selectRef?.current) {
      dispatch(setAboutUs(selectRef))
    }
  }, [dispatch])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <section
      viewport={{ amount: 0.2 }}
      initial="hidden"
      whileInView="visible"
      ref={selectRef}
      className={styles.section}>
      <div className={`${styles.container} ${isSmallScreen ? 'swapped' : ''}`}>
        {about &&
          about.map((item, index) => (
            <>
              {isSmallScreen ? (
                <>
                  <div key={item.id} className={styles.left}>
                    <div className={styles.leftContent}>
                      <div className={styles.textContent}>
                        <h1 className={styles.title}>{t('weAre')}</h1>
                        <h3 className={styles.desc}>
                          <Description desc={item.description} />
                        </h3>
                      </div>
                      <div className={styles.right}>
                        <img
                          src={
                            webpSupported ? item?.img?.webp : item?.img?.jpeg
                          }
                          alt="ABOUT IMG"
                          className={styles.img}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.btnContainer}>
                    <a target="_blank">
                      <button className={styles.btn}>{t('joinUs')}</button>
                    </a>{' '}
                  </div>
                </>
              ) : (
                <>
                  <div key={item.id} className={styles.left}>
                    <div className={styles.leftContent}>
                      <div className={styles.textContent}>
                        <h1 className={styles.title}>{t('weAre')}</h1>
                        <h3 className={styles.desc}>
                          <Description desc={item.description} />
                        </h3>
                      </div>
                      <div className={styles.btnContainer}>
                        <a
                          href="https://www.instagram.com/nelinka_for_you/"
                          target="_blank">
                          <button className={styles.btn}>{t('joinUs')}</button>
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                  <div className={styles.right}>
                    <img
                      src={webpSupported ? item?.img?.webp : item?.img?.jpeg}
                      alt="ABOUT IMG"
                      className={styles.img}
                    />
                  </div>
                </>
              )}
            </>
          ))}
      </div>
    </section>
  )
}
